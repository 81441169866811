import { Chip, SxProps, Theme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useCopy from '../utils/useCopy';

interface InlineValueWithCopyProps {
  value: string;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
}

const InlineValueWithCopy = ({ value, label, size, sx }: InlineValueWithCopyProps) => {
  const copyToClipboard = useCopy();

  return (
    <Chip
      sx={sx}
      label={label ?? value}
      size={size}
      onDelete={() => copyToClipboard(value ?? '')}
      deleteIcon={<ContentCopyIcon />}
      variant="outlined"
    />
  );
};

export default InlineValueWithCopy;
