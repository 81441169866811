import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import userManager from './auth/userManager';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Theme from './components/Theme';
import { UserProvider } from './contexts/userContext';

const Root = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 0 } },
  });

  return (
    <Theme>
      <BrowserRouter>
        <UserProvider userManager={userManager}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <CssBaseline />
              <Box sx={{ display: 'flex' }}>
                <Header />
                <MainContent />
              </Box>
            </SnackbarProvider>
          </QueryClientProvider>
        </UserProvider>
      </BrowserRouter>
    </Theme>
  );
};
export default Root;
