import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ICategoryItem, MappedQR } from './IQR';
import { useQRContext } from './QR';

interface QRCategoryProps {
  data: MappedQR;
  edit: boolean;
  setHasEdit: Dispatch<SetStateAction<boolean>>;
}

const QRCategory = ({ data, edit, setHasEdit }: QRCategoryProps) => {
  const [newMappingType, setNewMappingType] = useState<string>('');
  const [newMappingCategory, setNewMappingCategory] = useState<string>('');
  const [cats, setCats] = useState<ICategoryItem[]>([]);
  const { categories, categoryList } = useQRContext();

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    setHasEdit(true);
    const a = categories.find((d) => d.id === e.target.value);
    if (a?.categories) {
      setCats(a?.categories);
    }
    setNewMappingType(e.target.value);
    setNewMappingCategory('-1');
  };

  const handleCategoryChange = (e: SelectChangeEvent<string>) => {
    setHasEdit(true);
    setNewMappingCategory(e.target.value);
    if (e.target.value.length > 0) {
      data.categoryId = e.target.value;
    } else {
      data.categoryId = null;
    }
  };

  useEffect(() => {
    const selected = categoryList.find((d) => d.id === data.categoryId);
    if (selected) {
      const parent = categories.find((d) => d.id === selected.parentId);
      if (parent) {
        setCats(parent.categories);
      }
      setNewMappingType(selected.parentId as string);
      setNewMappingCategory(selected.id);
    }
  }, [data.categoryId, categoryList]);

  if (!edit) {
    return <Box>{categoryList.find((d) => d.id === data.categoryId)?.title}</Box>;
  }

  return (
    <>
      <Stack direction="row" gap={1}>
        <Box>
          <FormControl sx={{ width: '220px' }}>
            <Select
              id="type-select"
              size="small"
              value={newMappingType}
              onChange={handleTypeChange}>
              {categories.map(({ id, title }) => {
                return (
                  <MenuItem key={id} value={id} dense={true}>
                    {title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {newMappingType !== '' && cats && (
          <Box alignItems="flex-end" display="flex">
            <FormControl sx={{ width: '220px' }}>
              <Select
                size="small"
                value={newMappingCategory}
                onChange={handleCategoryChange}
                id="category-select">
                <MenuItem key="category-unselected" value="-1">
                  Choose a category
                </MenuItem>
                {cats
                  .filter((cat) => cat.isEnable || cat.id === newMappingCategory)
                  .map((cat) => {
                    return (
                      <MenuItem key={cat.id} value={cat.id} dense={true}>
                        {cat.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default QRCategory;
