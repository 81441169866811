import axios, { AxiosRequestHeaders } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { useGetHeaders } from '../../contexts/userContext';
import { Service } from '/src/types/site';

const baseUrl = process.env.REACT_APP_BAM_URL;

export interface Contract {
  id?: string;
  validTo: string | Date | null;
  validFrom: string | Date | null;
  description: string;
  enable: boolean;
  ownerId: string;
  services: string[] | Service[];
}

const accessToken = 'access_token';

export const getContracts = () => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;
  return useQuery(
    'getContracts',
    async () => {
      const req = await axios.get<Contract[]>(`${baseUrl}/Contract`, { headers });
      return req.data;
    },
    { enabled: headers !== null },
  );
};
export const getCustomerContract = (customerId: string) => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;

  return useQuery(
    ['getCustomerContracts', customerId],
    async () => {
      const req = await axios.get<Contract[]>(`${baseUrl}/Contract/customer/${customerId}`, {
        headers,
      });
      return req.data;
    },
    { enabled: headers !== null },
  );
};

export const getContract = (cId: string) => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;

  return useQuery<Contract>(
    ['getContract', cId],
    async () => {
      const req = await axios.get<Contract>(`${baseUrl}/Contract/${cId}`, { headers });
      return req.data;
    },
    { enabled: !!cId && cId !== 'new' && headers !== null },
  );
};

export const addContract = () => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;

  return useMutation(async (data: Contract) => {
    const req = await axios.post<Contract>(`${baseUrl}/Contract`, data, { headers });
    return req.data;
  });
};

export const editContract = () => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;

  return useMutation(async (data: Contract) => {
    const req = await axios.put<Contract>(`${baseUrl}/Contract/${data.id}`, data, {
      headers,
    });
    return req.data;
  });
};

export const deleteContract = () => {
  const headers = useGetHeaders<AxiosRequestHeaders>(accessToken)!;

  return useMutation(async (id: string) => {
    const req = await axios.delete<string>(`${baseUrl}/Contract/${id}`, { headers });
    return req.data;
  });
};

export default {
  getContracts,
  getContract,
  editContract,
  addContract,
  deleteContract,
};
