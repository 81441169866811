import React from 'react';
import styled, { keyframes } from 'styled-components';

import star from '../images/star.svg';

const Loader = () => {
  return (
    <LoaderWrapper>
      <AnimatedStar src={star} alt="logo" />
    </LoaderWrapper>
  );
};

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedStar = styled.img`
  animation: ${pulse} infinite 2s ease-in;
  height: 20vmin;
  pointer-events: none;
`;
const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default Loader;
