import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { useState } from 'react';
import { ComponentBase } from './ComponentChooser';
import { OptionalTooltip } from '../OptionalTooltip';
import useCopy from '../../utils/useCopy';

const ReadonlyValue = ({ comp }: ComponentBase) => {
  const [error, setError] = useState<boolean>(false);
  const copyToClipboard = useCopy();

  const onCopy = () => {
    copyToClipboard(comp.value as string)
      .then(() => setError(false))
      .catch(() => setError(true));
  };

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <TextField
          label={comp.title}
          variant="filled"
          size="small"
          fullWidth
          error={error}
          helperText={error && 'Could not save to clipboard'}
          defaultValue={comp.value}
          InputProps={{
            readOnly: true,
            endAdornment: navigator.clipboard && (
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" onClick={onCopy}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </OptionalTooltip>
  );
};

export default ReadonlyValue;
