import axios, { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const decodeGenericErrorMessage = (error: AxiosError | Error | unknown): string => {
  if (axios.isAxiosError(error)) {
    const dataObject = getErrorResponseObject(error);
    const message = (dataObject as { message?: string })?.message;

    switch (error.response?.status) {
      case 400:
        return tryDecodeRfc7231(dataObject) ?? 'Input data was not valid';
      case 401:
        return 'You are not authorized to do that';
      case 403:
        return 'You are not allowed to do that';
      case 404:
        return message ?? 'Entity could not be found';
      case 409:
        return 'Entity already exists';
      case 418:
        return 'The server is a teapot?';
      case 500:
        return 'The server ran into issues processing your request';
      default:
        return 'Problem communicating with server';
    }
  }

  return '';
};

const getErrorResponseObject = (error: AxiosError): unknown => {
  if (!error.response?.data) {
    return {};
  }

  if (typeof error.response.data === 'string') {
    return JSON.parse(error.response.data);
  }

  return error.response.data;
};

const tryDecodeRfc7231 = (dataObject: unknown) => {
  const rfc7231Object = dataObject as {
    type?: string;
    title?: string;
    errors?: Record<string, string[]>;
  };

  if (rfc7231Object?.type !== 'https://tools.ietf.org/html/rfc7231#section-6.5.1') {
    return undefined;
  }

  const errorMessages = Object.values(rfc7231Object.errors ?? {}).reduce(
    (acc, cur) => [...acc, ...cur],
    [],
  );

  return errorMessages[0] ?? rfc7231Object.title;
};
