import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useContext, useState } from 'react';

import { useUserContext } from '../contexts/userContext';
import { ColorModeContext } from './Theme';

const CurrentUser = ({ userName, picture }: { userName: string; picture: string }) => {
  const { signoutRedirect } = useUserContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const colorMode = useContext(ColorModeContext);

  const handleMenu = (event: React.MouseEvent<HTMLLIElement | HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeTheme = () => {
    const current = localStorage.getItem('admin_theme');
    localStorage.setItem('admin_theme', current === 'light' ? 'dark' : 'light');
    colorMode.toggleColorMode();
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit">
        <Avatar alt={userName} src={picture} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={changeTheme}>Change theme</MenuItem>
        <MenuItem onClick={signoutRedirect}>Log out</MenuItem>
      </Menu>
    </Box>
  );
};

export default CurrentUser;
