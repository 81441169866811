import { UserManager } from 'oidc-client-ts';

const { protocol, hostname } = window.location;
const port = window.location.port ? `:${window.location.port}` : '';
const protocolAndHost = `${protocol}//${hostname}${port}`;

const userManager = new UserManager({
  authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  extraQueryParams: {
    audience: process.env.REACT_APP_AUTH_AUDIENCE as string,
  },
  redirect_uri: `${protocolAndHost}/callback`,
  silent_redirect_uri: `${protocolAndHost}/callback`,
  scope: 'openid profile role email faqs:write restaurants:write rooms:write texts:write',
  response_type: 'code',
  automaticSilentRenew: true,
  checkSessionIntervalInSeconds: 5 * 60,
});

export default userManager;
