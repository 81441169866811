import { Tooltip } from '@mui/material';

interface OptionalTooltipProps {
  tooltip?: string;
}

export const OptionalTooltip = ({
  tooltip,
  children,
}: React.PropsWithChildren<OptionalTooltipProps>) => {
  // Become a transparent component if there are no tooltip
  if (!tooltip) return <>{children}</>;

  return <Tooltip title={tooltip}>{children as React.ReactElement}</Tooltip>;
};
