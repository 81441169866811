import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Page, { Header } from '../../components/Page';
import { Contract, getContract } from './api';
import AddEditContractForm from './components/AddEditContractForm';
import ViewContractsForm from './components/ViewContractsForm';

const Contracts = () => {
  const contract = useParams();
  const [showEditForm, setShowEditForm] = useState(false);
  const { data, error, isLoading } = getContract(contract?.id as string);
  useEffect(() => {
    setShowEditForm(false);
    if (contract.id) {
      setShowEditForm(true);
    }
  }, [contract]);

  return (
    <Page>
      <Header>Contracts</Header>
      <Card sx={{ overflow: 'visible' }}>
        <CardContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, pt: 1, pb: 2 }}>
                <ViewContractsForm />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading && (
                <Box>
                  <Paper variant="outlined" sx={{ p: 2, pt: 1, pb: 2 }}>
                    <Typography variant="h5">Loading contract</Typography>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Paper>
                </Box>
              )}
              {!isLoading && (error as boolean) && (
                <Box>
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      Could not load contract...
                    </Alert>
                  </Stack>
                </Box>
              )}
              {!error && !isLoading && showEditForm && (
                <Paper
                  variant="outlined"
                  sx={{ p: 2, pt: 1, pb: 0, position: 'sticky', top: '80px' }}>
                  <AddEditContractForm values={data as Contract} />
                </Paper>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Contracts;
