import {
  Checkbox,
  TextField,
  FormControl,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import Box from '@mui/material/Box';

import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { SchemaComponentWithKey } from '/src/types/schema';
import { OptionalTooltip } from '../OptionalTooltip';

type SelectValue = { key: string; value: string; selected: boolean };

const MultiSelect = ({ comp, id }: ComponentBase) => {
  const [chosenValue, setChosenValue] = useState<string | string[]>([]);
  const { handleChange } = useContext(FormContext);
  const lValue = comp.value as SelectValue[];
  const lData = comp.data as SchemaComponentWithKey[];
  const onHandleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event as SelectChangeEvent<string[]>;

    const selVal: SelectValue[] = [];
    (value as string[]).forEach((elm) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const val: SelectValue = (comp.data as SelectValue[]).find((d) => d.key === elm)!;
      if (val) {
        selVal.push(val);
      }
    });
    setChosenValue(value);
    handleChange(id, selVal);
  };

  useEffect(() => {
    //const lVal = comp?.value as SelectValue[];
    setChosenValue((lValue?.length && lValue.map((c: SelectValue) => c.key)) || []);
  }, []);

  const isChecked = (key: string) => {
    if (chosenValue && chosenValue.length) {
      return chosenValue.indexOf(key) > -1;
    }
    return false;
  };

  const renderValue = (selected: unknown) => {
    return (selected as string[])
      .map((s: string) => {
        return lData.find((d) => {
          return d.key === s;
        })?.value;
      })
      .join(', ');
  };

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <FormControl fullWidth>
          <TextField
            label={comp.title}
            size="small"
            select
            SelectProps={{
              multiple: true,
              onChange: onHandleChange,
              renderValue,
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            id={id}
            name={id}
            value={chosenValue}>
            {lData &&
              lData.map((kv) => (
                <MenuItem key={kv.key} value={kv.key}>
                  <Checkbox checked={isChecked(kv.key)} />
                  <ListItemText primary={kv.value as boolean} />
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Box>
    </OptionalTooltip>
  );
};

export default MultiSelect;
