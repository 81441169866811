import { Typography } from '@mui/material';
import React from 'react';

import { ComponentBase } from './ComponentChooser';

interface LabelProps extends ComponentBase {
  comp: { value: string; title: string };
  component: keyof JSX.IntrinsicElements;
}

const Label = ({ comp, component = 'h3' }: LabelProps) => {
  return (
    <Typography variant="h6" component={component} sx={{ mb: 1 }}>
      {comp.value || comp.title}
    </Typography>
  );
};

export default Label;
