import { Clear } from '@mui/icons-material';
import {
  Checkbox,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Customer } from '../../../types/customer';

interface SiteAccessFormProps {
  currentOwner: string;
  values: string[];
  chooseAble: Customer[];
  onValueChange: (value: string[]) => void;
  emptyText: string;
}

const SiteAccessForm = ({
  currentOwner,
  values,
  chooseAble,
  onValueChange,
  emptyText,
}: SiteAccessFormProps) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filterValue, setFilterValue] = useState('');

  const handleCheckboxChange = (value: string) => {
    const currentIndex = values.indexOf(value);
    const tmpServices = [...values];
    if (currentIndex === -1) {
      tmpServices.push(value);
    } else {
      tmpServices.splice(currentIndex, 1);
    }
    onValueChange(tmpServices);
  };
  const handleClearClick = () => {
    setFilterValue('');
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    if (chooseAble) {
      setCustomers(chooseAble);
    }
  }, [chooseAble]);

  useEffect(() => {
    const tmpCustomers = chooseAble.filter((cust) => {
      return cust.name.toLowerCase().startsWith(filterValue.toLowerCase());
    });
    setCustomers(tmpCustomers);
  }, [filterValue]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Select which customers that should have access to site
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              value={filterValue}
              id="newSiteName"
              label="Filter customers"
              onChange={handleFilter}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: filterValue ? 'visible' : 'hidden' }}
                    onClick={handleClearClick}>
                    <Clear />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          {customers &&
            customers.map((customer) => {
              const labelId = `checkbox-list-label-${customer.id}`;
              return (
                <Grid item key={customer.id} xs={3}>
                  <ListItemButton
                    role={undefined}
                    dense
                    disabled={currentOwner === customer.id}
                    onClick={() => handleCheckboxChange(customer.id)}>
                    <ListItemIcon>
                      <Checkbox
                        value={customer.id}
                        edge="start"
                        checked={values.indexOf(customer.id) > -1 || currentOwner === customer.id}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={customer.name} />
                  </ListItemButton>
                </Grid>
              );
            })}
          {customers && customers.length < 1 && <Typography>{emptyText}</Typography>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SiteAccessForm;
