import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { createContext, useContext, useState } from 'react';

// eslint-disable-next-line
import ComponentChooser, { ComponentBase } from './ComponentChooser';
import { ConfigurationPagesType, ConfigurationSection, SchemaComponent } from '/src/types/schema';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabPanel-content-${index}`}
      aria-labelledby={`sectionTab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const allyProps = (index: number) => {
  return {
    id: `sectionTab-${index}`,
    'aria-controls': `section-tabpanel-${index}`,
  };
};

interface ITabSharedContext {
  sharedData?: Record<string, unknown> | null;
  setSharedData: (data: Record<string, unknown>) => void;
  changeTab: (tabNumber: number) => void;
}

const ITabContext = createContext<ITabSharedContext | null>(null);
export const useTabContext = () => {
  const ctx = useContext(ITabContext);
  if (ctx === null) {
    throw new Error('useTabContext need to be inside a ITabContext');
  }
  return ctx;
};
interface SectionProps extends ComponentBase {
  ccOnChange: () => void;
}

const Section = ({ id, comp, ccOnChange }: SectionProps) => {
  const lComp = comp as ConfigurationSection;
  const selected = lComp.pages.findIndex((elm) => elm.selected);
  const [value, setValue] = useState<number>(selected > -1 ? selected : 0);
  const [sharedData, setSharedData] = useState<Record<string, unknown>>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  const changeTab = (tabNumber: number) => {
    setValue(tabNumber);
  };

  if (lComp.pages.length > 1) {
    return (
      <ITabContext.Provider value={{ changeTab, sharedData, setSharedData }}>
        <Box key={id}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label={comp.title}>
              {lComp.pages.map((page, index) => {
                return (
                  <Tab label={page.value} {...allyProps(index)} key={`sectionPanel-${page.key}`} />
                );
              })}
            </Tabs>
            {lComp.pages.map((page, index) => {
              const part = lComp.configurationPages.find(
                (d) => d.key === page.key,
              ) as ConfigurationPagesType;
              const lValue = part?.value as ConfigurationPagesType;
              return (
                <TabPanel value={value} index={index} key={page.key}>
                  {Object.keys(lValue || {}).map((obj) => {
                    const lp = lValue[obj as keyof ConfigurationPagesType] as SchemaComponent;
                    return (
                      <ComponentChooser
                        comp={lp}
                        key={`tabPanel-content-${obj}`}
                        id={`${id}.configurationPages.${page.key}.${obj}`}
                        required={!lp?.optional}
                        ccOnChange={ccOnChange}
                      />
                    );
                  })}
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      </ITabContext.Provider>
    );
  }

  const pageId = lComp.pages[0].key;
  const part = lComp.configurationPages.find((d) => d.key === pageId);
  return (
    <Box key={`singleTab-section-${pageId}`}>
      {Object.keys(part?.value || {}).map((obj) => {
        const lValue = part?.value as ConfigurationPagesType;
        const lp = lValue[obj as keyof ConfigurationPagesType] as SchemaComponent;

        return (
          <ComponentChooser
            comp={lp}
            key={`tabPanel-content-${obj}`}
            id={`${id}.configurationPages.${part?.key}.${obj}`}
            required={!lp?.optional}
          />
        );
      })}
      <Divider />
    </Box>
  );
};

export default Section;
