import fileDownload from 'js-file-download';

export interface CsvExportConfig<Tin> {
  headers: string[];
  buildRow: (row: Tin) => string[];
  buildFilename: (rows: Tin[]) => string;
  separator?: string;
}

export const useCsvExport = <Tin>({
  headers,
  buildRow,
  buildFilename,
  separator = ';',
}: CsvExportConfig<Tin>) => {
  const separatorReplacement = { ',': '.', ';': ' ' }[separator] ?? '';

  return (rows: Tin[]) => {
    const csvRows = [headers.join(separator)];

    rows.map(buildRow).forEach((row) => {
      const washedColumns = row.map((x) =>
        x.replaceAll(separator, separatorReplacement).replace(/[\r\n]+/g, ' '),
      );
      csvRows.push(washedColumns.join(separator));
    });

    fileDownload(csvRows.join('\n'), buildFilename(rows));
  };
};
