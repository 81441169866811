import { Skeleton } from '@mui/material';

import { SiteInfo } from '../../../types/site';
import { getSite } from '../api';
import AddSiteForm from './AddSiteForm';

interface EditSiteFormProps {
  siteId: string;
}

const EditSiteForm = ({ siteId }: EditSiteFormProps) => {
  const { data, isLoading } = getSite(siteId);
  if (isLoading) {
    return <Skeleton />;
  }
  return <AddSiteForm oldSite={data as SiteInfo} />;
};
export default EditSiteForm;
