import React, { useEffect, useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';

import { TabPanel } from '../../Section';
import { useGetApiUrl, useGetBranding } from './api';
import { useUserContext } from '../../../../contexts/userContext';
import { Site } from '../../../../types/site';
import CustomDesign from './CustomDesign';
import BackgroundImageDesign from './BackgroundImageDesign';

interface BorderBranding {
  borderColor: string;
  text: string;
  imageUrl: string;
  textColor: string;
  textSize: number;
}
export interface Branding {
  id: string | null;
  name: string;
  backgroundColor?: string;
  foregroundColor: string;
  backgroundImageUrl?: string;
  topBorderBranding: BorderBranding;
  bottomBorderBranding: BorderBranding;
  iconUrl: string;
  iconSize: 0;
  qrCodeScaleFactor: number;
}
export interface BrandingChild {
  borderColor?: string;
  text?: string;
  imageUrl?: string;
  textSize?: string;
}
export interface BrandingResponse {
  data: Branding[];
}

const QRDesign = () => {
  const apiUrl = useGetApiUrl();
  const { currentSite } = useUserContext();
  const { data: brandings } = useGetBranding(currentSite as Site);
  const [tab, setTab] = useState<number>(0);
  const [customBrandings, setCustomBrandings] = useState<Branding[]>([]);
  const [backgroundBrandings, setBackgroundBrandings] = useState<Branding[]>([]);

  useEffect(() => {
    const backgroundBrandings = brandings?.filter((b) => b.backgroundImageUrl);
    const customBrandings = brandings?.filter((b) => !b.backgroundImageUrl);
    setBackgroundBrandings(backgroundBrandings || []);
    setCustomBrandings(customBrandings || []);
  }, [brandings, apiUrl]);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (brandings && brandings?.length < 1) {
    return <div>Loading....</div>;
  }

  return (
    <Paper variant="elevation" elevation={0}>
      <Box component="form">
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Custom Design" id={'tab-0'} aria-controls="tab-0" />
          <Tab label="Background Design" id={'tab-1'} aria-controls="tab-1" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <p>Custom design</p>
          <CustomDesign brandings={customBrandings} currentSite={currentSite} apiUrl={apiUrl} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <BackgroundImageDesign
            brandings={backgroundBrandings}
            currentSite={currentSite}
            apiUrl={apiUrl}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

export default QRDesign;
