import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import Page, { Header } from './Page';

interface Matrix {
  [key: string]: Record<string, string>;
}

const fetchInfo = async (service: string, postfix: string, path = '') => {
  const realPostFix = postfix === '-prod' ? '' : postfix;
  const url = `https://${service}${realPostFix}.coor.digital${path}`;

  const response = await axios.get(url);
  if (typeof response.data === 'string') {
    return response.data;
  }

  if (typeof response.data === 'object') {
    const { productVersion } = response.data as { productVersion: string };
    return productVersion;
  }

  return 'Unknown';
};

const BuildMatrix = () => {
  const prefixes = ['.dev', '.test', '.stage', '.prod'];
  const services = [
    { service: 'bam', path: '' },
    { service: 'servicerequest', path: '' },
    { service: 'servicerequestjobs', path: '' },
    { service: 'csm', path: '' },
    { service: 'findspace', path: '' },
    { service: 'files', path: '' },
    { service: 'food', path: '' },
    { service: 'parking', path: '' },
    { service: 'notification', path: '' },
    { service: 'to', path: '' },
    { service: 'client', path: '/api/version' },
  ];
  const [matrix, setMatrix] = useState<Matrix>({});

  useEffect(() => {
    const fetchAllInfo = async () => {
      const [bam, sr, srj, csm, space, files, food, parking, notification, to, client] =
        await Promise.all(
          services.map(async ({ service, path }) => {
            const [dev, test, stage, prod] = await Promise.all<string>(
              prefixes.map((p) => fetchInfo(service, `${p}`, path || '').catch(() => 'Unknown')),
            );
            return { dev, test, stage, prod };
          }),
        );
      setMatrix({
        Bam: bam,
        ServiceRequest: sr,
        ServiceRequestsJobs: srj,
        CSM: csm,
        FindSpace: space,
        Files: files,
        Food: food,
        Parking: parking,
        Client: client,
        Notification: notification,
        Redirect: to,
      });
    };
    void fetchAllInfo();
  }, [setMatrix]);

  return (
    <Page>
      <Header>Build matrix</Header>
      <p>Here you can see a build matrix</p>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Service</TableCell>
              {prefixes &&
                prefixes.map((d) => {
                  return <TableCell key={`table-row-${d}`}>{d.replace(/[.-]/, '')}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {matrix &&
              Object.keys(matrix).map((c) => {
                const { dev, stage, test, prod } = matrix[c];
                return (
                  <TableRow key={c}>
                    <TableCell sx={{ fontWeight: 'bold' }}>{c}</TableCell>
                    <TableCell>{dev}</TableCell>
                    <TableCell>{test}</TableCell>
                    <TableCell>{stage}</TableCell>
                    <TableCell>{prod}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default BuildMatrix;
