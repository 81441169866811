import SendIcon from '@mui/icons-material/Send';
import { Button, Card, CardContent, Grid, Paper } from '@mui/material/';
import { useSnackbar } from 'notistack';
import React from 'react';

import Page, { Header } from '../../components/Page';
import { doPublishCustomer } from './api';
import AddCustomerForm from './components/AddCustomerForm';
import EditCustomerForm from './components/EditCustomerForm';

const Customers = () => {
  const publishCustomer = doPublishCustomer();
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const handlePublishButton = async () => {
    try {
      await publishCustomer.mutateAsync();
      showSnack('Auth0 was told to be updated with all customers.', { variant: 'success' });
    } catch (error) {
      showSnack('Customers could not be updated.', { variant: 'warning' });
    }
  };

  return (
    <Page>
      <Header>Customers</Header>
      <Card>
        <CardContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, pt: 1, pb: 0 }}>
                <EditCustomerForm />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, pt: 1, pb: 0 }}>
                <AddCustomerForm />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 0 }} justifyContent="flex-end" display="flex">
            <Button
              id="publishCustomersButton"
              variant="contained"
              color="success"
              type="button"
              onClick={handlePublishButton}
              endIcon={<SendIcon />}>
              Publish customers to auth0
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Customers;
