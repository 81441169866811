import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { darken, lighten } from 'polished';
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';

const black = '#333333';
const white = '#FDFDFD';
const lightGray = '#F5F5F5';
const borderColor = '#dddddd';
const gray = '#D1D1D1';
const primary = '#F0843E';
const secondary = '#333333';
const danger = 'red';
const warning = 'yellow';
const success = 'green';
const realWhite = '#ffffff';
const info = '#0288d1';

const COLORS = {
  primary,
  secondary,
  black,
  lightGray,
  gray,
  white,
  danger,
  warning,
  success,
  borderColor,
  realWhite,
  info,
};

declare module '@mui/material/styles' {
  interface Theme {
    whiteColor: string;
    old: Record<string, string>;
  }
  interface ThemeOptions {
    whiteColor: string;
    old: Record<string, string>;
  }
}

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    background: {
      default: COLORS.lightGray,
    },
    action: {
      selected: darken(0.15, COLORS.lightGray),
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLORS.black,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.realWhite,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingRight: '5px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1200,
        },
      },
    },
  },
  transitions: {},
  whiteColor: COLORS.white,
  old: {
    primaryColor: COLORS.primary,
    secondaryColor: COLORS.secondary,
    blackColor: COLORS.black,
    whiteColor: COLORS.white,
    grayColor: COLORS.gray,
    lightGrayColor: COLORS.lightGray,
    borderColor: COLORS.borderColor,
    disabledTextColor: COLORS.gray,
    dangerColor: COLORS.danger,
    warningColor: COLORS.warning,
    successColor: COLORS.success,
    headerBgColor: COLORS.black,
    subHeaderBgColor: COLORS.lightGray,
    subNavBgColor: darken(0.05, COLORS.lightGray),
    borderRadius: '2px',
  },
});

const darkColors = {
  textColor: '#f8f7f5',
  backgroundColor: '#1a1919',
};
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: darken(0.1, COLORS.primary),
    },
    secondary: {
      main: darken(0.1, COLORS.secondary),
    },
    background: {
      default: darkColors.backgroundColor,
      paper: darkColors.backgroundColor,
    },
    common: {
      black: COLORS.white,
      white: COLORS.black,
    },
    divider: 'rgba(255,255,255,0.2)',
    text: {
      primary: darkColors.textColor,
      secondary: darken(0.05, darkColors.textColor),
      disabled: darken(0.1, darkColors.textColor),
    },
    action: {
      active: darken(0.05, darkColors.textColor),
      hover: lighten(0.1, darkColors.backgroundColor),
      selected: lighten(0.15, darkColors.backgroundColor),
    },
    success: {
      main: darken(0, COLORS.success),
      light: lighten(0.1, COLORS.success),
      dark: darken(0, COLORS.success),
      contrastText: darkColors.textColor,
    },
    info: {
      main: darken(0.1, COLORS.info),
      light: lighten(0.1, COLORS.info),
      dark: darken(0.2, COLORS.info),
      contrastText: darkColors.textColor,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: darken(0.1, COLORS.black),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: darkColors.backgroundColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingRight: '5px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1200,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: COLORS.black,
          color: darkColors.textColor,
        },
      },
    },
  },
  transitions: {},
  whiteColor: COLORS.white,
  old: {
    primaryColor: COLORS.primary,
    secondaryColor: COLORS.secondary,
    blackColor: COLORS.black,
    whiteColor: COLORS.white,
    grayColor: COLORS.gray,
    lightGrayColor: COLORS.lightGray,
    borderColor: COLORS.borderColor,
    disabledTextColor: COLORS.gray,
    dangerColor: COLORS.danger,
    warningColor: COLORS.warning,
    successColor: COLORS.success,
    headerBgColor: COLORS.white,
    subHeaderBgColor: COLORS.lightGray,
    subNavBgColor: darken(0.05, COLORS.lightGray),
    borderRadius: '2px',
  },
});

const getDesignTokens = (mode: PaletteMode) => {
  return mode === 'light' ? lightTheme : darkTheme;
};

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

interface ThemeProps extends PropsWithChildren {}

const Theme = ({ children }: ThemeProps) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(() => {
    return {
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    };
  }, []);

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    const current = localStorage.getItem('admin_theme');
    if (current) {
      setMode(current === 'light' ? 'dark' : 'light');
    }
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Theme;
