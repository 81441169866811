import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { SelectChangeEvent } from '@mui/material';

import { Branding, BrandingChild } from '../QRDesign';
import { Site } from '/src/types/site';
import {
  useAddBrandingMutation,
  useDeleteBrandingMutation,
  useUpdateBrandingMutation,
} from '../api';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { MappedQR, PostQrCode } from '../IQR';
import { decodeGenericErrorMessage } from '../../../../../utils/error';
import { QRselectedRowsKey } from '../Row';
import { useTabContext } from '../../../Section';

interface UseBrandingManagerProps {
  brandings: Branding[] | undefined;
  apiUrl: string;
  defaultBrandingId: string;
  currentSite: string | Site | null;
}
export const useBrandingManager = ({
  apiUrl,
  brandings,
  defaultBrandingId,
  currentSite,
}: UseBrandingManagerProps) => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const addMutation = useAddBrandingMutation();
  const deleteMutation = useDeleteBrandingMutation();
  const updateMutation = useUpdateBrandingMutation();
  const queryClient = useQueryClient();
  const { sharedData } = useTabContext();

  const [localBranding, setLocalBranding] = useState<Branding | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedBranding, setSelectedBranding] = useState<Branding | null>(null);

  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [previewError, setPreviewError] = useState<string | null>(null);
  //Image to the left (border image preview)
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  //Image to the right (qr-code preview)
  const [previewImage, setPreviewImage] = useState<string>(
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  );
  const [imageDimension, setImageDimention] = useState({ width: 512, height: 512 });
  const selectedQrCodes = (sharedData?.[QRselectedRowsKey] as MappedQR[]) || [];
  const [qrCodesToPrint, setQrCodesToPrint] = useState<string>(
    selectedQrCodes.map((d) => d.hri).join('\r\n'),
  );
  const [printPng, setPrintPng] = useState<boolean>(false);

  const [usePrintingGuides, setUsePrintingGuides] = useState<boolean>(false);

  useEffect(() => {
    if (brandings && !localBranding) {
      const defaultBranding = brandings.find((b) => b.id === defaultBrandingId);

      if (defaultBranding) {
        setLocalBranding(structuredClone(defaultBranding));
        setSelectedBranding(defaultBranding);
        void generatePreview(defaultBranding);
        setIsReadOnly(true);
      }
    }
  }, [brandings, defaultBrandingId]);

  /**
   *
   */
  const generatePreview = useCallback(
    async (branding?: Branding | undefined) => {
      if (!branding) {
        setPreviewError('Branding data is required to generate a preview.');
        return;
      }

      setIsGeneratingPreview(true);
      setPreviewError(null);

      try {
        const fetchUrl = `${apiUrl}api/qr`;
        const fetchBody = {
          customBranding: branding,
          type: 1,
          addPrintingGuides: usePrintingGuides,
          responseFormat: 0,
          keys: ['preview'],
        };
        const a = await fetch(fetchUrl, {
          headers: { 'content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(fetchBody),
        });
        if (a.status !== 200) {
          throw new Error('Could not generate preview');
        }
        const body = await a.blob();
        const imageUrl = URL.createObjectURL(body);
        setPreviewImage(imageUrl);

        // Get the image dimensions
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          setImageDimention({ width, height });
        };
      } catch (error) {
        setPreviewImage(
          'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
        );
        showSnack(
          'Could not generate preview, the server responded with an error. Try again later.',
          { variant: 'warning' },
        );
        setPreviewError('Failed to generate preview. Please try again later.');
        setIsGeneratingPreview(false);
      }
    },
    [apiUrl, usePrintingGuides, localBranding, setSelectedBranding],
  );

  /**
   *
   * @param branding
   */
  const fetchPdf = async (branding: Branding) => {
    const numberOfQrCodesToPrint = qrCodesToPrint.split('\n').length;
    let doPrintPng = printPng;
    if (numberOfQrCodesToPrint > 1) {
      doPrintPng = false;
    }
    const postObj: PostQrCode = {
      keys: qrCodesToPrint.split('\n').map((d) => d.replace(/[^\w:]/g, '')),
      type: 1,
      brandingDefinition: branding?.id || null,
      responseFormat: doPrintPng ? 0 : 1,
      addPrintingGuides: usePrintingGuides,
    };
    try {
      const response = await axios.post<Blob>(`${apiUrl}api/qr`, postObj, {
        responseType: 'blob',
      });
      if (doPrintPng) {
        fileDownload(response.data, 'qr.png', 'image/png');
      } else {
        fileDownload(response.data, 'qr.pdf', 'application/pdf');
      }
    } catch (err) {
      const errorMessage = decodeGenericErrorMessage(err);
      showSnack(`Could not generate pdf. ${errorMessage}. Try again later.`, {
        variant: 'warning',
      });
    }
  };

  /**
   *
   */
  const createNewBranding = () => {
    const newBranding: Branding = structuredClone({
      ...brandings?.find((b) => b.id === defaultBrandingId),
      name: 'New branding',
      id: null,
    }) as Branding;

    if (newBranding) {
      setIsReadOnly(false);
      setLocalBranding(newBranding);
      void generatePreview(newBranding);
    }
  };

  /**
   *
   * @returns
   */
  const saveBranding = async () => {
    if (localBranding === null) {
      showSnack('Cant save branding, no branding selected', { variant: 'warning' });
      return;
    }
    if (!localBranding.id || localBranding?.id === null) {
      const { data: mutdata } = await addMutation.mutateAsync(localBranding);
      setSelectedBranding(mutdata);
      setLocalBranding(mutdata);
      showSnack('Saved branding, looking good! ❤️', { variant: 'success' });
    } else {
      await updateMutation.mutateAsync(localBranding);
      showSnack(`Updated branding, it looks nice! 😍`, { variant: 'success' });
    }
    if (currentSite) {
      await queryClient.invalidateQueries(['branding', (currentSite as Site)?.id]);
    }
  };

  /**
   *
   */
  const deleteBranding = async () => {
    if (isReadOnly) {
      showSnack(`Cant delete default branding`, { variant: 'warning' });
      return;
    }
    if (!localBranding?.id) {
      showSnack(`Cant delete branding, no branding selected`, { variant: 'warning' });
      return;
    }

    await deleteMutation.mutateAsync(localBranding?.id || '');
    await queryClient.invalidateQueries(['branding', (currentSite as Site)?.id]);
    setShowConfirmDelete(false);
    setLocalBranding(null);
    const defaultBranding = brandings?.find((b) => b.id === defaultBrandingId);
    if (defaultBranding) {
      setSelectedBranding(defaultBranding);
    }
    showSnack(`Deleted branding`, { variant: 'success' });
  };

  /**
   *
   */
  const brandingChanged = (e: SelectChangeEvent<string>) => {
    const selected = brandings?.find((b) => b.id === e.target.value);
    setIsReadOnly(false);
    if (selected?.id === defaultBrandingId) {
      setIsReadOnly(true);
    }
    if (selected) {
      setLocalBranding(structuredClone(selected) || null);
      setSelectedBranding(selected || null);
      void generatePreview(selected);
    }
  };

  /**
   *
   */
  const handleChange = <T>(property: string) => {
    if (property.match(/\./)) {
      const [parent, child] = property.split('.');
      return (e: T) => {
        const realVal: string =
          typeof e === 'string' ? e : (e as React.ChangeEvent<HTMLInputElement>).target.value;

        if (localBranding !== null) {
          const b = localBranding[parent as keyof Branding] as BrandingChild;
          setLocalBranding({
            ...localBranding,
            [parent]: { ...b, [child]: realVal },
          } as Branding);
        }
      };
    }
    return (e: T) => {
      const realVal: string =
        typeof e === 'string' ? e : (e as React.ChangeEvent<HTMLInputElement>).target.value;
      setLocalBranding({
        ...localBranding,
        [property]: realVal,
      } as Branding);
    };
  };

  /**
   *
   * @param e
   * @param value
   */
  const iconSizeChanged = (e: Event, value: number | number[]) => {
    if (localBranding !== null) {
      setLocalBranding({ ...localBranding, iconSize: value as number } as Branding);
    }
  };

  /**
   *
   * @param prop
   * @returns
   */
  const textSizeChanged = (prop: keyof Branding) => {
    return (e: Event, value: number | number[]) => {
      if (localBranding !== null) {
        const a = {
          ...localBranding,
          [prop]: { ...(localBranding[prop] as BrandingChild), textSize: value },
        };
        setLocalBranding(a);
      }
    };
  };

  /**
   *
   */
  const handleDeleteButton = () => {
    setShowConfirmDelete(true);
  };

  /**
   *
   */
  const closeDialog = () => {
    setShowConfirmDelete(false);
  };

  const handleQrCodesToPrintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQrCodesToPrint(e.target.value);
    if (e.target.value.split('\n').length > 0) {
      setPrintPng(false);
    }
  };

  /**
   *
   * @param url
   * @param hide
   */
  const handleUpdatePreview = (url: string, hide: boolean = false) => {
    setPreviewUrl(url);
    setShowPreview(true);
    if (hide) {
      setShowPreview(false);
    }
  };
  /**
   *
   */
  const closePreview = () => {
    setShowPreview(false);
  };
  /**
   *
   * @param e
   * @param value
   */
  const handleScaleFactorChange = (e: Event, value: number | number[]) => {
    if (localBranding !== null) {
      setLocalBranding({ ...localBranding, qrCodeScaleFactor: value as number } as Branding);
    }
  };

  return {
    generatePreview,
    fetchPdf,
    createNewBranding,
    imageDimension,
    previewImage,
    isGeneratingPreview,
    previewError,
    saveBranding,
    deleteBranding,
    brandingChanged,
    handleChange,
    iconSizeChanged,
    textSizeChanged,
    handleDeleteButton,
    closeDialog,
    handleQrCodesToPrintChange,
    handleUpdatePreview,
    closePreview,
    previewUrl,
    showPreview,
    isReadOnly,
    localBranding,
    setLocalBranding,
    handleScaleFactorChange,
    selectedBranding,
    showConfirmDelete,
    setUsePrintingGuides,
    printPng,
    usePrintingGuides,
    qrCodesToPrint,
    setPrintPng,
  };
};
