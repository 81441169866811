import {
  TableRow,
  TableCell,
  Collapse,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Location, { LocationData } from '../../Location';
import { MappedQR } from './IQR';
import { useEffect, useState } from 'react';
import QRCategory from './Category';
import { useBatchUpdateQRCodes } from './api';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useTabContext } from '../../Section';
import { QRselectedRowsKey } from './Row';
interface RowToolsProps {
  onSave: () => void;
}

const RowTools = ({ onSave }: RowToolsProps) => {
  const data = { locationId: '', location: null, categoryId: null, propertyId: null } as MappedQR;
  const queryClient = useQueryClient();
  const { sharedData } = useTabContext();

  const [localData] = useState<MappedQR>(data);
  const [hasEdit, setHasEdit] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const batchEdit = useBatchUpdateQRCodes();
  const [selectedRows, setSelectedRows] = useState<MappedQR[] | null>(null);
  const [show, setShow] = useState(false);

  const doSave = async () => {
    setShowDialog(false);
    setHasEdit(false);
    if (!selectedRows) return;

    const values: MappedQR[] = selectedRows?.map((o) => {
      const retValue = { ...o };
      if (localData.locationId !== '') {
        retValue.locationId = localData.locationId;
      }
      if (localData.categoryId !== null) {
        retValue.categoryId = localData.categoryId;
      }
      return retValue;
    });

    if (values.length > 0) {
      try {
        const res = await batchEdit.mutateAsync(values);
        if (res.status === 204) {
          onSave();
          await queryClient.invalidateQueries('getQrCodes');
          showSnack(`Updated succeded, ${values.length} rows changed.`, { variant: 'success' });
        } else {
          showSnack('Could not update values, try again.', { variant: 'warning' });
        }
      } catch (err) {
        showSnack('Update failed', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (sharedData && sharedData[QRselectedRowsKey]) {
      const d = sharedData[QRselectedRowsKey] as MappedQR[];
      setShow(d.length > 0);
      setSelectedRows(d);
    }
  }, [sharedData]);

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={2}></TableCell>
        <TableCell sx={{ p: show ? 1 : 0 }}>
          <Collapse in={show} timeout={300}>
            <Location data={localData as LocationData} edit={show} setHasEdit={setHasEdit} />
          </Collapse>
        </TableCell>
        <TableCell sx={{ p: show ? 1 : 0 }}>
          <Collapse in={show} timeout={300}>
            <QRCategory data={localData} edit={show} setHasEdit={setHasEdit} />
          </Collapse>
        </TableCell>
        <TableCell sx={{ p: show ? 1 : 0 }} align="right">
          {hasEdit && show && (
            <Button onClick={() => setShowDialog(true)} variant="contained" color="success">
              Save
            </Button>
          )}
        </TableCell>
      </TableRow>
      <Dialog open={showDialog}>
        <DialogContent>
          Are you sure you want to save the changes? {selectedRows?.length} rows will be affected.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="warning">
            No
          </Button>
          <Button onClick={doSave} color="success" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RowTools;
