import React, { useEffect } from 'react';

import ContractsForm from '../features/Contracts';

const ContractsRoute = () => {
  useEffect(() => {
    document.title = 'Contracts | Simply by Coor';
  }, []);

  return <ContractsForm />;
};
export default ContractsRoute;
