import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

import Page, { Header } from '../components/Page';

const SignOutCallback = () => {
  return (
    <Page>
      <Header>Signed Out</Header>
      <Card variant="outlined">
        <CardContent>
          You were successfully signed out.{' '}
          <Button component={Link} to="/">
            Sign in again
          </Button>
        </CardContent>
      </Card>
    </Page>
  );
};

export default SignOutCallback;
