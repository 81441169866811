import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Stack,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState, useRef, Context } from 'react';

import { SchemaComponent } from '../../types/schema';
import { FormContext, FormContextType } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { VisuallyHiddenInput } from './ServiceRequest/Locations/Locations';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { useCsvExport } from '../../utils/useCsvExport';

export interface CustomerSchema {
  id: SchemaComponent;
  name: SchemaComponent;
  externalId: SchemaComponent;
}

let defaultCustomer: CustomerSchema | null = null;
const CustomerCollection = ({ id, comp }: ComponentBase) => {
  defaultCustomer = structuredClone<CustomerSchema>(comp.templateCustomer as CustomerSchema);

  const ref = useRef<HTMLInputElement>(null);

  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { handleChange, setFormIsDirty } = useContext<FormContextType>(
    FormContext as unknown as Context<FormContextType>,
  );

  const [customers, setCustomers] = useState<CustomerSchema[]>([]);
  const [dirty, setDirty] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const exportCustomersToCsv = useCsvExport<CustomerSchema>({
    headers: ['ExternalId', 'Name'],
    buildRow: (row) => [(row.externalId.value as string) ?? '', (row.name.value as string) ?? ''],
    buildFilename: () => 'customers.csv',
  });

  const deleteCustomer = () => {
    if (deleteIndex !== null) {
      const tmpCustomers = [...customers];
      tmpCustomers.splice(deleteIndex, 1);
      setCustomers(tmpCustomers);
      setDeleteIndex(null);
    }
    setFormIsDirty(true);
    setDirty(true);
    setShowConfirmDelete(false);
  };

  const addCustomerRow = (e: React.MouseEvent) => {
    e.preventDefault();
    setDirty(true);
    setFormIsDirty(true);
    setCustomers([...customers, { ...defaultCustomer } as CustomerSchema]);
  };
  const onChange = (index: number, path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setFormIsDirty(true);
    e.preventDefault();
    const tmpCustomers: CustomerSchema[] = [...customers];
    tmpCustomers[index][path as keyof CustomerSchema].value = e.target.value;
    setCustomers(tmpCustomers);
  };

  const parseCustomers = (loc: string) => {
    const lines = loc.split(/\r?\n/g).slice(1);
    const newData = lines
      ?.filter((l) => l.length > 0)
      .map((line) => {
        const [externalId, name] = line.split(';');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const customer = structuredClone<CustomerSchema>(defaultCustomer!);
        customer.externalId.value = externalId;
        customer.name.value = name;
        return customer;
      });
    setShowFileUpload(false);
    setCustomers(newData);
    setDirty(true);
    setUploadDisabled(false);
  };

  const handleShowImport = () => {
    setShowFileUpload(true);
  };

  const handleFileUpload = (e: React.MouseEvent) => {
    e.preventDefault();
    setUploadDisabled(true);
    if (ref.current) {
      const file = ref.current.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          parseCustomers((reader.result as string) || '');
        };
        reader.readAsText(file, 'ISO-8859-1');
      }
    }
  };

  const handleExport = () => {
    exportCustomersToCsv(customers);
  };

  const confirmDeleteCustomer = (index: number) => () => {
    setShowConfirmDelete(true);
    setDeleteIndex(index);
  };

  const closeDialog = () => {
    setDeleteIndex(null);
    setShowConfirmDelete(false);
  };

  const saveSettings = () => {
    setDirty(false);
    setFormIsDirty(false);
    handleChange(id, { ...comp, value: customers } as SchemaComponent, true);

    showSnack('Updated customer data, you can now save your changes to the server!', {
      variant: 'success',
    });
  };
  const dirtyStyle = dirty
    ? { backgroundColor: 'rgba(255, 0, 0, 0.05)', border: '1px solid #faa' }
    : {};

  useEffect(() => {
    setCustomers([...(comp.value as CustomerSchema[])]);
  }, [comp.value]);

  return (
    <Card sx={{ ...dirtyStyle, my: 2 }} variant="outlined" key={id}>
      <CardContent>
        <Typography component="h3">{comp.title}</Typography>
        <Typography component="small" sx={{ display: 'block', fontSize: '0.75rem' }}>
          {comp.toolTip}
        </Typography>

        {customers.map((customer, index) => {
          const key = `customer-${index}`;
          return (
            <Box sx={{ my: 2 }} key={key}>
              <Stack spacing={2} direction="row" justifyContent="flex-start">
                <FormControl sx={{ flex: '1 1 auto' }}>
                  <TextField
                    size="small"
                    label={customer.externalId.title}
                    value={customer.externalId.value || ''}
                    onChange={onChange(index, 'externalId')}
                    required
                  />
                </FormControl>
                <FormControl sx={{ flex: '1 1 auto' }}>
                  <TextField
                    size="small"
                    label={customer.name.title}
                    value={customer.name.value || ''}
                    onChange={onChange(index, 'name')}
                    required
                  />
                </FormControl>
                <FormControl sx={{ flex: '0 1 auto' }}>
                  <IconButton
                    aria-label="Delete this location"
                    onClick={confirmDeleteCustomer(index)}
                    color="error"
                    defaultValue={index}
                    sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </Stack>
            </Box>
          );
        })}
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap="1rem"
          sx={{ mt: 1, pt: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
          <Button onClick={addCustomerRow} variant="contained" color="info" startIcon={<AddIcon />}>
            Add customer
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleShowImport}
            startIcon={<UploadFileIcon />}>
            Import from csv
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleExport}
            startIcon={<FileDownloadTwoTone />}>
            Export to csv
          </Button>
          <Button
            sx={{ ml: 'auto' }}
            color="success"
            variant="contained"
            disabled={!dirty}
            onClick={saveSettings}
            endIcon={<SaveAltIcon />}>
            Save Customers
          </Button>
        </Stack>
        <Dialog
          open={showFileUpload}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-discription">
          <DialogTitle id="alert-dialog-title">Chose file</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Select the file you want to import customers from, all data will be replaced when
              saving.
            </Typography>
            <Button
              component="label"
              color="info"
              variant="contained"
              startIcon={<UploadFileIcon />}>
              Upload file{' '}
              <VisuallyHiddenInput
                name="file"
                id="csvfile"
                type="file"
                ref={ref}
                multiple={false}
                accept=".csv, .txt"
              />
            </Button>
            <Paper sx={{ marginTop: 2, p: 1 }} elevation={3}>
              <p>
                The expected input format is a CSV file using <b>;</b> for seperation, with a header
                row. It should have the following columns:
              </p>
              <pre>ExternalId;Name</pre>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setShowFileUpload(false);
              }}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={handleFileUpload}
              disabled={uploadDisabled}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-discription">
          <DialogTitle id="alert-dialog-title">Delete customer</DialogTitle>
          <DialogContent>Are you sure you want to delete this customer?</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="outlined" color="warning" onClick={deleteCustomer}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default CustomerCollection;
