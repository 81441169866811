import { Box, FormControl, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { FormContext } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { OptionalTooltip } from '../OptionalTooltip';

const Number = ({ id, comp, required = true }: ComponentBase) => {
  const { handleChange } = useContext(FormContext);
  const [value, setValue] = useState<string>(comp.value as string);
  const [error, setError] = useState(false);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const {
      target: { value: tmpValue },
    } = e;

    if (required && tmpValue.length < 1) {
      setError(true);
    }
    setValue(tmpValue);
  };
  const onBlur = () => {
    handleChange(id, value);
  };
  useEffect(() => {
    handleChange(id, comp.value as string);
  }, []);

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <FormControl fullWidth variant="outlined" required={required} error={error}>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', type: 'number' }}
            label={comp.title}
            autoComplete="false"
            id={id}
            required={required}
            defaultValue={value}
            variant="outlined"
            size="small"
            onChange={onChange}
            onBlur={onBlur}
            name={id}
            fullWidth
          />
        </FormControl>
      </Box>
    </OptionalTooltip>
  );
};

export default Number;
