import { Send, Delete } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Checkbox,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Stack,
  Box,
  Button,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material';
import { addDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getCustomers } from '../../Customers/api';
import { getServices } from '../../Sites/api';
import ServicesForm from '../../Sites/components/ServicesForm';
import { addContract, editContract, deleteContract, Contract } from '../api';
import { Service } from '/src/types/site';
import parseISO from 'date-fns/parseISO';

const defaultSettings: Contract = {
  enable: true,
  description: '',
  ownerId: '-1',
  validFrom: new Date(),
  validTo: null,
  services: [],
};

interface AddEditContractFormProps {
  values: Contract;
}

const AddEditContractForm = ({ values }: AddEditContractFormProps) => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const [contractInfo, setContractInfo] = useState(values || { ...defaultSettings });
  const [customerError, setCustomerError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const addContractMutation = addContract();
  const editContractMutation = editContract();
  const deleteContractMutation = deleteContract();
  const customers = getCustomers();

  const servicesToChooseFrom = getServices();

  const changePropertyValue = (key: keyof Contract) => {
    return (value: string | boolean | Service[]) => {
      setContractInfo({ ...contractInfo, [key]: value });
    };
  };

  const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCustomerError(true);
    if (e.target.value !== '-1') {
      setCustomerError(false);
    }
    setContractInfo({ ...contractInfo, ownerId: e.target.value });
  };

  const handleValidFrom = (e: Date | string | null) => {
    let tmpData;
    if (e !== 'Invalid Date') {
      tmpData = { ...contractInfo, validFrom: e };
      //Ensure that validTo is one day efter validFrom, but only if validFrom already has a value
      if (e && tmpData.validTo && e > (tmpData.validTo as Date)) {
        tmpData.validTo = addDays(e as Date, 1);
      }
    } else {
      tmpData = { ...contractInfo, validFrom: null };
    }
    setContractInfo(tmpData);
  };

  const handleValidTo = (e: Date | string | null) => {
    let tmpData;
    if (e !== 'Invalid Date') {
      tmpData = { ...contractInfo, validTo: e };
      if (e && e < (tmpData.validFrom as Date)) {
        tmpData.validFrom = addDays(e as Date, -1);
      }
    } else {
      tmpData = { ...contractInfo, validTo: null };
    }
    setContractInfo(tmpData);
  };

  const doSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (contractInfo.ownerId !== '-1') {
      if (contractInfo?.id) {
        try {
          await editContractMutation.mutateAsync(contractInfo);
          showSnack('Contract was updated.', { variant: 'success' });
        } catch (error) {
          showSnack('Could not update contract, try again.', { variant: 'warning' });
        }
      } else {
        try {
          const res = await addContractMutation.mutateAsync(contractInfo);
          await queryClient.invalidateQueries('getCustomerContracts');
          showSnack('Contract was added.', { variant: 'success' });
          navigate(`/contracts/${res.id}`);
        } catch (error) {
          showSnack('Could not add contract, try again.', { variant: 'warning' });
        }
      }
    } else {
      setCustomerError(true);
    }
  };

  const handleDeleteContract = async () => {
    setConfirmDelete(false);
    try {
      await deleteContractMutation.mutateAsync(contractInfo.id as string);
      navigate('/contracts/');
      showSnack('Contract was deleted successfully', { variant: 'success' });
      await queryClient.invalidateQueries('getCustomerContracts');
    } catch (error) {
      showSnack('Contract could not be deleted', { variant: 'warning' });
    }
  };

  const showDeleteDialog = () => {
    setConfirmDelete(true);
  };

  const closeDialog = () => {
    setConfirmDelete(false);
  };

  useEffect(() => {
    setContractInfo(values || { ...defaultSettings });
  }, [values]);

  return (
    <Box component="form" onSubmit={doSubmit}>
      <Dialog
        open={confirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete contract</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this contract? It can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={handleDeleteContract}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h5" sx={{ mb: 2 }}>
        {contractInfo?.id ? 'Edit contract' : 'Add a new contract'}
      </Typography>
      <Grid container spacing={2} columnSpacing={{ xs: 2 }} sx={{ mb: 2 }} direction="column">
        <Grid item xs={12} md={6}>
          {customers.isLoading && <Skeleton />}
          {customers.data && (
            <FormControl fullWidth variant="standard" sx={{ my: 1 }} error={customerError}>
              <TextField
                select
                label={!contractInfo?.id ? 'Select a customer' : 'Customer'}
                value={contractInfo.ownerId}
                onChange={handleOwnerChange}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ mt: 2 }}
                required
                disabled={!!contractInfo?.id}
                error={customerError}
                id="selectCustomerSelect">
                <MenuItem key="-1" value="-1">
                  Select a owner for contract
                </MenuItem>
                {customers.data.map((cust) => {
                  return (
                    <MenuItem key={cust.id} value={cust.id}>
                      {cust.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              {customerError ? (
                <FormHelperText>You must select a valid customer</FormHelperText>
              ) : null}
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard" sx={{ my: 1 }} error={false}>
            <TextField
              label="Descibe the contract"
              size="small"
              multiline
              required
              minRows={2}
              value={contractInfo.description}
              onChange={(e) => changePropertyValue('description')(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true, clearable: true },
                    textField: { size: 'small', required: true },
                  }}
                  label="Valid from"
                  value={parseISO(contractInfo.validFrom as string)}
                  onChange={handleValidFrom}
                  format="yyyy-MM-dd"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true, clearable: true },
                    textField: { size: 'small', required: false },
                  }}
                  label="Valid to"
                  value={parseISO(contractInfo.validTo as string)}
                  onChange={handleValidTo}
                  format="yyyy-MM-dd"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <FormControlLabel
            label="Should the contract be enabled?"
            control={
              <Checkbox
                id="customerEnable"
                inputProps={{ 'aria-label': 'Enabled' }}
                checked={contractInfo.enable}
                onChange={(e) => changePropertyValue('enable')(e.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item>
          <ServicesForm
            values={contractInfo.services as Service[]}
            chooseAble={servicesToChooseFrom}
            onValueChange={(e) => changePropertyValue('services')(e)}
          />
        </Grid>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {contractInfo?.id ? (
            <Button
              id="deleteContractButton"
              variant="contained"
              color="error"
              endIcon={<Delete />}
              onClick={showDeleteDialog}>
              Delete contract
            </Button>
          ) : (
            ''
          )}
          <Button
            id="addContractButton"
            variant="contained"
            color="success"
            type="submit"
            endIcon={<Send />}>
            {contractInfo?.id ? 'Update contract' : 'Add contract'}
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default AddEditContractForm;
