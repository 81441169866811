import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { decodeGenericErrorMessage } from '../../../utils/error';
import { addCustomer } from '../api';

const AddCustomerForm = () => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const [enabled, setEnabled] = useState(true);
  const [customerName, setCustomerName] = useState('');
  const [customerGuid, setCustomerGuid] = useState('');
  const queryClient = useQueryClient();
  const [guidError, setGuidError] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.target.checked);
  };

  const addCustomerMutation = addCustomer();
  const doSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const postData = { enable: enabled, name: customerName, id: customerGuid };
      await addCustomerMutation.mutateAsync(postData);
      await queryClient.invalidateQueries('getCustomers');
      showSnack(`The new customer (${customerName}) was added successfully`, {
        variant: 'success',
      });
      setCustomerName('');
      setCustomerGuid('');
      setEnabled(true);
    } catch (error) {
      const subMessage = decodeGenericErrorMessage(error);
      showSnack(`Could not add customer. ${subMessage}`, { variant: 'warning' });
    }
  };
  const checkError = (val: string) => {
    setGuidError(false);
    const match = val.match(
      /^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$/gm,
    );
    if (!match) {
      setGuidError(true);
    }
  };
  return (
    <Box component="form" onSubmit={doSubmit}>
      <Typography variant="h5">Add a new customer</Typography>
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 2 }}
        sx={{ mb: 2, pt: 2 }}
        direction="column">
        <Grid item xs={12}>
          <TextField
            id="customerName"
            label="Customer Name"
            variant="outlined"
            size="small"
            value={customerName}
            required
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="customerGuid"
            label="Customer GUID"
            variant="outlined"
            size="small"
            value={customerGuid}
            inputProps={{
              pattern:
                '^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$',
            }}
            required
            onChange={(e) => setCustomerGuid(e.target.value)}
            onBlur={(e) => checkError(e.target.value)}
            fullWidth
            error={guidError}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item xs>
              <FormControlLabel
                label="Should the customer be enabled?"
                control={<Checkbox id="customerEnable" checked={enabled} onChange={handleChange} />}
              />
            </Grid>
            <Grid item>
              <Button
                id="addCustomerButton"
                variant="contained"
                color="success"
                type="submit"
                endIcon={<SendIcon />}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCustomerForm;
