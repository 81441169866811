import { Box, FormControl, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { FormContext } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { OptionalTooltip } from '../OptionalTooltip';

const TextBox = ({ id, comp, required = true }: ComponentBase) => {
  const { handleChange } = useContext(FormContext);
  const [value, setValue] = useState(comp.value);
  const [error, setError] = useState(false);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    if (required && e.target.value.length < 1) {
      setError(true);
    }
    setValue(e.target.value);
  };
  const onBlur = () => {
    handleChange(id, value as string);
  };
  useEffect(() => {
    handleChange(id, comp.value as string);
  }, []);

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <FormControl fullWidth variant="outlined" required={required} error={error}>
          <TextField
            label={comp.title}
            autoComplete="false"
            id={id}
            required={required}
            defaultValue={value}
            variant="outlined"
            size="small"
            onChange={onChange}
            onBlur={onBlur}
            name={id}
            fullWidth
          />
        </FormControl>
      </Box>
    </OptionalTooltip>
  );
};

export default TextBox;
