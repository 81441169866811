// import Form from '../styles/Form';
import Input from '@mui/material/Input';
import React, { useContext, useEffect } from 'react';

import { FormContext } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';

const Hidden = ({ id, comp }: ComponentBase) => {
  const { handleChange } = useContext(FormContext);
  useEffect(() => {
    if (comp.value) {
      handleChange(id, comp.value ?? null);
    }
  }, [comp]);

  return (
    <Input
      type="hidden"
      disableUnderline
      defaultValue={comp.data}
      id={id}
      name={id}
      sx={{ display: 'none' }}
    />
  );
};

export default Hidden;
