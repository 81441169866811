import { useContext, useState } from 'react';

import { ComponentBase } from './ComponentChooser';
import { SchemaComponent, SchemaComponentWithKey } from '/src/types/schema';
import Location, { LocationData } from './Location';
import FormContext from '../../contexts/FormContext';
import { Box, Typography } from '@mui/material';
import { SRLocation } from './ServiceRequest/Locations/api';
import { OptionalTooltip } from '../OptionalTooltip';

interface LocationSelectProps extends ComponentBase {
  comp: SchemaComponentWithKey;
}

const LocationSelect = ({ id, comp }: LocationSelectProps) => {
  const [selectedValue] = useState<LocationData>({
    locationId: ((comp.value as SRLocation)?.id as string) || null,
    location: comp.value as SRLocation,
  });
  const [, setHasEdit] = useState<boolean>(false);
  const { handleChange } = useContext(FormContext);

  const onChange = (value: string) => {
    const tmpValue = {
      id: value,
    };
    handleChange(id, tmpValue as SchemaComponent);
  };

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <Typography component={'label'} sx={{ mb: 1 }}>
          {comp.title}
        </Typography>

        <Location data={selectedValue} edit={true} onChange={onChange} setHasEdit={setHasEdit} />
      </Box>
    </OptionalTooltip>
  );
};

export default LocationSelect;
