import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export const Header = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h2" component="h2" sx={{ my: 2 }}>
    {children}
  </Typography>
);
const Page = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: '85rem', my: 0, mx: 'auto', p: [2.5, 2] }}>{children}</Box>
  );
};
export default Page;
