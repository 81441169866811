import { useEffect } from 'react';

import SitesPage from '../features/Sites';

const SitesRoute = ({ path }: { path: string }) => {
  useEffect(() => {
    document.title = 'Sites | Simply by Coor';
  }, []);

  return <SitesPage path={path} />;
};

export default SitesRoute;
