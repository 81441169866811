import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useState } from 'react';

import { FormContext } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { SchemaComponentWithKey } from '/src/types/schema';
import { OptionalTooltip } from '../OptionalTooltip';

interface CheckBoxProps extends ComponentBase {
  comp: SchemaComponentWithKey;
}

const CheckBox = ({ id, comp }: CheckBoxProps) => {
  const [isChecked, setIsChecked] = useState(!!comp.value);
  const { handleChange } = useContext(FormContext);
  const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    handleChange(id, e.target.checked);
  };

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <FormControlLabel
        control={
          <Checkbox
            defaultValue={comp.value as string}
            id={id}
            checked={isChecked}
            onChange={updateVal}
            name={comp.key}
          />
        }
        label={comp.title ?? ''}
      />
    </OptionalTooltip>
  );
};

export default CheckBox;
