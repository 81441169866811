import { Button, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../../contexts/userContext';
import { useGetServiceInfo } from '../../../../features/serviceConfiguration/api';
import { ServiceInfo, Site } from '/src/types/site';
import { useGenerateNewMutation } from './api';

const GenerateNew = () => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [numberToReserve, setNumberToReserve] = useState<number>(0);

  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { serviceId, sub } = useParams();
  const { data: serviceInfo, isSuccess } = useGetServiceInfo<ServiceInfo>(serviceId || '');
  const [serviceData, setServiceData] = useState<ServiceInfo | null>(null);
  const queryClient = useQueryClient();
  const { currentSite } = useUserContext();
  const newMutation = useGenerateNewMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (!isNaN(val) && val > 0) {
      setNumberToReserve(val);
    }
  };

  const generateCodes = async () => {
    setIsGenerating(true);
    try {
      if (!serviceData) {
        throw new Error('No service data in generate codes');
      }
      await newMutation.mutateAsync({ numberToReserve, sub: sub as string });
      setIsGenerating(false);
      setNumberToReserve(0);
      showSnack('QR-Codes was generated', { variant: 'success' });
      const configPath = `${serviceInfo?.basePath}${serviceInfo?.configurationPath}/${sub}/${
        (currentSite as Site).id
      }`;

      await queryClient.invalidateQueries(['serviceConfiguration', configPath]);
    } catch (_) {
      showSnack('Could not reserve more qr-codes, try again later.', {
        variant: 'warning',
      });
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setServiceData(serviceInfo);
    }
  }, [serviceInfo]);

  return (
    <Stack direction="row">
      <TextField
        size="small"
        type="number"
        value={numberToReserve}
        onChange={handleChange}
        inputProps={{ max: 100, min: 0 }}
      />
      <Button
        sx={{ ml: 2 }}
        onClick={generateCodes}
        disabled={isGenerating}
        variant="contained"
        color="success">
        Generate
      </Button>
    </Stack>
  );
};

export default GenerateNew;
