import axios, { AxiosRequestHeaders } from 'axios';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';

import { useGetHeaders, useUserContext } from '../../../../contexts/userContext';
import { ServiceInfo, Site } from '../../../../types/site';
import {
  useGetServiceInfo,
  baseUrl,
  accessToken,
} from '../../../../features/serviceConfiguration/api';
import { SRMetaData } from '../Metadata/Metadata';

/**
 * Interface for Location
 */
export interface SRLocation {
  id?: string;
  parentId: string | null;
  title: string;
  externalId: string;
  externalSiteId: string;
  descriptionRequired: boolean;
  locationType: string;
  value: SRLocation[];
  isSelectable?: boolean;
  hasChildren?: boolean;
  isHidden: boolean;
  metadata: SRMetaData[];
  metadataIds?: string[];
}

export interface SRImportLocation {
  parentId: string;
  title: string;
  externalId: string;
  locationType: string;
  descriptionRequired: boolean;
  isSelectable: boolean;
  externalSiteId: string;
  isHidden: boolean;
}
/**
 * Interface for LocationsResponse
 */
export interface LocationsResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
  locations: SRLocation[];
}

export interface ExportResponse {
  locations: string;
}
export interface ImportLocationsResponse {
  greatestDepth: number;
  numImportedRows: number;
  numNewLocations: number;
  locations: SRLocation[];
}
export const locationGuids = [
  { key: '39266d8e-311c-4cb5-9759-73928a55bd1a', value: 'Building/Area' },
  { key: '60063346-b8d9-43bb-8520-ecd68a940bd3', value: 'Floor' },
  { key: 'de81c69f-2826-4662-8bd4-a98fe9c88ea5', value: 'Room' },
  { key: 'f96b4c84-abd4-45d8-a264-67794bdd323b', value: 'Asset' },
];

/**
 * Hook to get locations for a given site.
 * @param {string} siteId - The ID of the site.
 * @param {number} page - The page number for pagination. Default is 0.
 * @param {number} pageSize - The number of items per page for pagination. Default is 20.
 * @returns {UseQueryResult<LocationsResponse>} Query object containing locations data and query metadata.
 */
export const useGetLocations = (
  siteId: string,
  page: number = 0,
  pageSize: number = 20,
  parentId: string | null,
): UseQueryResult<LocationsResponse> => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useQuery<LocationsResponse>(
    ['getLocations', page, pageSize, siteId, parentId],
    async () => {
      const url = parentId
        ? `${baseUrl}${serviceInfo?.basePath}/configuration/${siteId}/location/?page=${page}&pageSize=${pageSize}&parentId=${parentId}`
        : `${baseUrl}${serviceInfo?.basePath}/configuration/${siteId}/location/?page=${page}&pageSize=${pageSize}`;
      const reqData = await axios.get<LocationsResponse>(url, {
        headers,
      });
      return reqData.data;
    },
    { enabled: !!serviceInfo?.id },
  );
};

export const useGetLocation = (siteId: string, locationId: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useQuery<SRLocation>(
    ['getLocation', locationId],
    async () => {
      const url = `${baseUrl}${serviceInfo?.basePath}/configuration/${siteId}/location/${locationId}`;
      const reqData = await axios.get<SRLocation>(url, {
        headers,
      });
      return reqData.data;
    },
    { enabled: !!serviceInfo?.id && siteId.length > 0 && locationId.length > 0 },
  );
};

interface ServiceRequestLocation {
  id: string;
  title: string;
  externalId: string;
  value: ServiceRequestLocation[];
  locationType: string;
  descriptionRequired: boolean;
  isSelectable: boolean;
}

export const useSearchLocation = (siteId: string, search: string, maxNumberOfLocations = 50) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useQuery<ServiceRequestLocation[]>(
    ['searchLocations', siteId, search, maxNumberOfLocations],
    async () => {
      const url = `${baseUrl}${serviceInfo?.basePath}/configuration/${siteId}/location/search?title=${search}`;
      const reqData = await axios.get<ServiceRequestLocation[]>(url, {
        headers,
      });
      return reqData.data;
    },
    { enabled: !!serviceInfo?.id && search.length > 0, retry: false, refetchOnWindowFocus: false },
  );
};

export const useUpdateLocationMutation = () => {
  const queryClient = useQueryClient();
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const { currentSite } = useUserContext();
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useMutation(
    async (data: SRLocation) => {
      return axios.post<SRLocation>(
        `${baseUrl}${serviceInfo?.basePath}/configuration/${(currentSite as Site).id}/location/${
          data.id
        }`,
        data,
        { headers },
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['getLocations']);
      },
    },
  );
};

export const useImportLocationMutation = () => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const { currentSite } = useUserContext();
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);
  const sendHeaders = {
    Authorization: headers.Authorization,
    'Content-Type': 'text/csv',
    accept: 'application/json',
  };

  return useMutation(async (data: { data: string; isPreview: boolean }) => {
    return axios.post<ImportLocationsResponse>(
      `${baseUrl}${serviceInfo?.basePath}/configuration/${
        (currentSite as Site).id
      }/location/import/csv${data.isPreview ? '?preview=true' : '?preview=false'}`,
      data.data,
      { headers: sendHeaders },
    );
  });
};

export const useExportLocationMutation = (siteId: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useMutation(async () => {
    const url = `${baseUrl}${serviceInfo?.basePath}/configuration/${siteId}/location/export`;
    const reqData = await axios.get<string>(url, {
      headers,
    });
    return reqData.data;
  });
};

export const useInvaliateLocationsQuery = () => {
  const queryClient = useQueryClient();
  return () => {
    void queryClient.invalidateQueries(['getLocations']);
  };
};

export const useAddLocationMutation = () => {
  const queryClient = useQueryClient();
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const { currentSite } = useUserContext();
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useMutation(
    async (data: SRLocation) => {
      return axios.post<string>(
        `${baseUrl}${serviceInfo?.basePath}/configuration/${(currentSite as Site).id}/location`,
        data,
        { headers },
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['getLocations']);
      },
    },
  );
};

export const useDeleteLocationMutation = () => {
  const queryClient = useQueryClient();
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const { currentSite } = useUserContext();
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useMutation(
    async (id: string) => {
      return axios.delete<string>(
        `${baseUrl}${serviceInfo?.basePath}/configuration/${
          (currentSite as Site).id
        }/location/${id}`,
        { headers },
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['getLocations']);
      },
    },
  );
};

interface SRMetaDataRequest {
  metadata: { value: SRMetaData[] };
}

export const useMetaData = (siteId: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const serviceInfoId = 'faee5b37-5caa-4942-a134-399e8004572f';
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceInfoId);

  return useQuery<SRMetaData[]>(
    ['metadata', siteId],
    async () => {
      const url = `${baseUrl}${serviceInfo?.basePath}/configuration/metadata/${siteId}`;
      const reqData = await axios.get<SRMetaDataRequest>(url, {
        headers,
      });
      return reqData?.data?.metadata?.value || [];
    },
    { enabled: !!serviceInfo?.id, retry: false, refetchOnWindowFocus: false },
  );
};
