import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AccessDeniedRoute from '../routes/AccessDenied';
import BuildMatrixRoute from '../routes/BuildMatrix';
import Main from '../routes/Main';
import SignIn from '../routes/SignIn';
import SignOut from '../routes/SignOut';

const MainContent = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
      }}>
      <Toolbar />
      <Routes>
        <Route path="*" element={<Main />} />
        <Route path="callback" element={<SignIn />} />
        <Route path="signedout" element={<SignOut />} />
        <Route path="accessdenied" element={<AccessDeniedRoute />} />
        <Route path="buildmatrix" element={<BuildMatrixRoute />} />
      </Routes>
    </Box>
  );
};

export default MainContent;
