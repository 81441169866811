/* eslint-disable react/prop-types */
import {
  AddBusiness,
  Assignment,
  Dashboard,
  DashboardCustomize,
  ExpandLess,
  ExpandMore,
  LocalParking,
  MapsHomeWork,
  NotificationAdd,
  Restaurant,
  RoomService,
  SvgIconComponent,
  Info,
  Warning,
  Error,
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Collapse, List, ListItem, ListItemButton, Popover, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { createElement, PropsWithChildren, useEffect, useState } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

import { getUserAccess, useUserContext } from '../contexts/userContext';
import { getServiceForSite, ServiceInfoWithSubpages } from '../features/Sites/api';
import SiteChanger from './SiteChanger';
import { PartInfo, Site } from '../types/site';

const drawerWidth = 300;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open
    ? {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
        overflowX: 'hidden',
        '& .MuiDrawer-paper': closedMixin(theme),
        [theme.breakpoints.up('sm')]: {
          width: `calc(${theme.spacing(7)} + 1px)`,
        },
      }),
}));

const getColorForStatus = (status: number) => {
  switch (status) {
    case 3:
      return 'error';
    case 2:
      return 'warning';
    default:
      return 'info';
  }
};

const getIconForStatus = (status: number) => {
  {
    switch (status) {
      case 3:
        return <Error color="error" />;
      case 2:
        return <Warning color="warning" />;
      default:
        return <Info color="info" />;
    }
  }
};

const headerStyle = {
  padding: '1rem 1.5rem 0.5rem',
  fontWeight: 'normal',
  fontSize: '1.125rem',
};
interface IServiceInfoOption {
  icon: SvgIconComponent;
}
interface IServiceInfo {
  [key: string]: IServiceInfoOption;
}

const servicesInfo: IServiceInfo = {
  'b0336424-dba3-4b5c-8dd9-1c53f72c1e8c': { icon: LocalParking },
  'fbc24e3d-7c72-4540-b6e2-21d4324db05c': { icon: Restaurant },
  'faee5b37-5caa-4942-a134-399e8004572f': { icon: RoomService },
  '1ff62c72-f34f-4f5c-a276-4d887fc6cebf': { icon: MapsHomeWork },
  '39b48b7f-499c-4f24-a9ec-8397f1de7bce': { icon: WysiwygIcon },
  '496b816b-3942-491b-bd10-8cf9dff90cbc': { icon: AttachFileIcon },
  '1fde0d8f-0024-4a5d-a737-13dfe7fbce08': { icon: AlbumOutlinedIcon },
  '292ffba6-233c-40b2-b4f7-ff5c0d2ca6c7': { icon: LocalShippingIcon },
  'b33f45e4-a102-4273-9a4e-36526dbd0e29': { icon: NotificationAdd },
  'e8f95bd8-9620-4c6a-a9bd-46ec558e3dd3': { icon: GroupIcon },
};

interface ServiceButtonProps {
  service: ServiceInfoWithSubpages;
}

const ServiceButton = ({ service }: ServiceButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubPages = service.subPages?.length > 0;
  const location = useLocation();

  const parts = matchRoutes([{ path: '/services/:serviceId/:part' }], location) ?? [];

  const {
    params: { serviceId, part },
  } = parts[0] ?? { params: { serviceId: '', part: '' } };

  const hasPartErrors = hasSubPages && service.subPages.some((x) => x.errors?.length > 0);
  const highestStatus = service.subPages?.reduce((acc, cur) => Math.max(acc, cur.status), 0) ?? 0;
  const allErrors =
    service.subPages?.reduce<string[]>((acc, cur) => [...acc, ...(cur.errors ?? [])], []) ?? [];

  let serviceInfo = servicesInfo[service.id];

  useEffect(() => {
    if (serviceId && serviceId === service.id && !isOpen) {
      setIsOpen(true);
    }
  }, [serviceId, service.id]);

  if (!serviceInfo) {
    serviceInfo = { icon: QuestionMarkIcon };
  }

  return (
    <>
      {!hasSubPages && (
        <ListItemButton key={service.id} component={Link} to={`/services/${service.id}`}>
          <ListItemIcon>{createElement(serviceInfo.icon, { key: service.id })}</ListItemIcon>
          <ListItemText primary={service.name} />
        </ListItemButton>
      )}

      {hasSubPages && (
        <>
          <LocalListItemButton
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            subPages={service.subPages}
            serviceId={service.id}
            currentServiceId={serviceId as string}>
            <ListItemIcon>
              {hasPartErrors ? (
                <ErrorsPopover
                  errors={[...new Set(allErrors)]}
                  icon={createElement(serviceInfo.icon, {
                    key: service.id,
                    color: hasPartErrors ? getColorForStatus(highestStatus) : undefined,
                  })}
                />
              ) : (
                createElement(serviceInfo.icon, {
                  key: service.id,
                })
              )}
            </ListItemIcon>
            <ListItemText primary={service.name} />
          </LocalListItemButton>
          {service.subPages.length > 1 && (
            <Collapse in={isOpen} timeout="auto">
              {service.subPages.map((subpage) => {
                const extraUrl = subpage.configurationPath;
                return (
                  <ListItemButton
                    sx={{
                      pl: 10,
                      background: (theme) => {
                        return extraUrl === part && service.id === serviceId
                          ? theme.palette.action.selected
                          : 'none';
                      },
                    }}
                    to={`/services/${service.id}/${extraUrl}`}
                    component={Link}
                    key={`${service.id}-${subpage.name}`}>
                    {subpage.errors?.length > 0 && (
                      <ErrorsPopover
                        errors={subpage.errors}
                        icon={getIconForStatus(subpage.status)}
                        sx={{
                          width: '1.5rem',
                          marginLeft: '-2rem',
                          minWidth: 0,
                          marginRight: '0.5rem',
                        }}
                      />
                    )}
                    <ListItemText primary={`- ${subpage.name}`} />
                  </ListItemButton>
                );
              })}
            </Collapse>
          )}
        </>
      )}
    </>
  );
};

interface LocalListItemButtonProps extends PropsWithChildren {
  subPages: PartInfo[];
  serviceId: string;
  isOpen: boolean;
  currentServiceId: string;
  onClick: () => void;
}
const LocalListItemButton = ({
  subPages,
  serviceId,
  isOpen,
  onClick,
  children,
  currentServiceId,
}: LocalListItemButtonProps) => {
  const useDirectLink = subPages.length === 1;

  if (useDirectLink) {
    return (
      <ListItemButton
        key={serviceId}
        sx={{
          background: (theme) => (serviceId === currentServiceId ? theme.palette.divider : ''),
        }}
        onClick={onClick}
        to={`/services/${serviceId}/${subPages[0].configurationPath}`}
        component={Link}>
        {children}
      </ListItemButton>
    );
  }
  return (
    <ListItemButton key={serviceId} onClick={onClick}>
      {children}
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );
};

const ErrorsPopover = ({
  errors,
  icon,
  ...rest
}: { errors: string[]; icon: React.ReactElement } & ListItemIconProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ListItemIcon
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        {...rest}>
        {icon}
      </ListItemIcon>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <List>
          {errors.map((error, i) => (
            <ListItem key={i}>{error}</ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

const Navigation = ({ open }: { open: boolean }) => {
  const { user, currentSite } = useUserContext();
  const hasAccess = getUserAccess(user);
  const site = currentSite as Site;

  if (!hasAccess) {
    return (
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <Box>
          <Button component={Link} to="/">
            Sign In
          </Button>
        </Box>
      </Drawer>
    );
  }
  const siteServices = getServiceForSite(site?.id as string);
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar />
      <Box>
        {!user && (
          <Button component={Link} to="/">
            Sign In
          </Button>
        )}
        {user && (
          <>
            {open && <Typography sx={headerStyle}>Customers</Typography>}

            <ListItemButton component={Link} to="/customers/">
              <ListItemIcon>
                <AddBusiness />
              </ListItemIcon>
              <ListItemText primary="Handle customers" />
            </ListItemButton>

            <ListItemButton component={Link} to="/contracts/">
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText primary="Handle contracts" />
            </ListItemButton>

            <Divider />
            {open && <Typography sx={headerStyle}>Sites</Typography>}

            <ListItemButton component={Link} to="/sites/new">
              <ListItemIcon>
                <DashboardCustomize />
              </ListItemIcon>
              <ListItemText primary="Add new site" />
            </ListItemButton>
            {open && <SiteChanger />}

            {site && site.id !== '-1' && site.id !== undefined && (
              <ListItemButton component={Link} to={`/sites/${site.id}`}>
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="Edit site" />
              </ListItemButton>
            )}
          </>
        )}
        {user && site && site.id !== '-1' && siteServices?.data && (
          <>
            <Divider />
            {open && <Typography sx={headerStyle}>Services</Typography>}
            {siteServices.data.map((service) => {
              return <ServiceButton key={service.id} service={service} />;
            })}
          </>
        )}
        {user && (
          <>
            <Divider />
            <ListItemButton component={Link} to="/buildmatrix">
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Build matrix" />
            </ListItemButton>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default Navigation;
