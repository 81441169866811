/* eslint react/prop-types: 0 */
import { Delete, Send } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Customer } from '../../../types/customer';
import { deleteCustomer, editCustomer, getCustomers } from '../api';

const EditCustomerForm = () => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const queryClient = useQueryClient();
  const [oldCustomer, setOldCustomer] = useState<Customer | undefined>();
  const [inputCustomer, setInputCustomer] = useState('');
  const [oldCustomerData, setOldCustomerData] = useState<Customer | undefined>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const editMutation = editCustomer();
  const deleteCustomerMutation = deleteCustomer();
  const { isLoading, data, error } = getCustomers();

  // const handleCustomerChange = (e, b) => {
  //   setOldCustomer(e.target.value);
  //   const dd = data.find((d) => d.id === e.target.value);
  //   if (dd) {
  //     setOldCustomerData(dd);
  //   } else {
  //     setOldCustomerData(null);
  //   }
  // };

  const setOldCustomerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const old = { ...oldCustomerData, name: e.target.value };
    setOldCustomerData(old as Customer);
  };

  const handleDeleteCustomer = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { id } = oldCustomerData || {};
    if (id) {
      const result = await deleteCustomerMutation.mutateAsync(id);
      if (result.status === 200) {
        setOldCustomerData(undefined);
        setOldCustomer(undefined);
        showSnack('The customer was deleted', { variant: 'success' });
        await queryClient.invalidateQueries('getCustomers');
      }
    }
    setConfirmDelete(false);
  };

  const handleEditCustomer = async () => {
    const postData = {
      enable: oldCustomerData?.enable || false,
      name: oldCustomerData?.name || '',
      id: oldCustomerData?.id || '',
    };
    try {
      await editMutation.mutateAsync(postData);
      await queryClient.invalidateQueries('getCustomers');
      showSnack('The customer was updated successfully.', { variant: 'success' });
    } catch (err) {
      /* eslint-disable no-console */
      console.log(err);
    }
  };
  const closeDialog = () => {
    setConfirmDelete(false);
  };
  const handleOldEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
    const old = { ...oldCustomerData, enable: e.target.checked };
    setOldCustomerData(old as Customer);
  };

  return (
    <>
      <Dialog
        open={confirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete contract</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this contract? It can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={handleDeleteCustomer}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Edit customer
        </Typography>
        {isLoading && <Skeleton />}
        {(error as boolean) && (
          <Stack>
            <Alert severity="warning">
              <AlertTitle>Error</AlertTitle>
              Could not fetch customers
            </Alert>
          </Stack>
        )}
        {data && (
          <Autocomplete
            id="customer-chooser-combobox"
            disablePortal
            options={data}
            disableClearable
            autoHighlight
            value={oldCustomer}
            inputValue={inputCustomer}
            onChange={(e, cust) => {
              setOldCustomer(cust);
              setOldCustomerData(cust);
            }}
            onInputChange={(e, a) => {
              setInputCustomer(a);
            }}
            getOptionLabel={(props) => {
              if (typeof props === 'string') {
                const a = data.find((d) => {
                  return d.id === props;
                });
                return a?.name || '';
              }
              return props.name;
            }}
            fullWidth
            renderOption={(props, cust) => {
              return (
                <MenuItem key={cust.id} {...props} value={cust.id}>
                  {cust.name}
                </MenuItem>
              );
            }}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  variant="outlined"
                  size="small"
                  label="Select customer"
                  inputProps={{ ...props.inputProps }}
                  fullWidth
                />
              );
            }}
          />
        )}
        <Box sx={{ my: 2 }}>
          {oldCustomerData && (
            <Grid container spacing={2} columnSpacing={{ xs: 2 }}>
              <Grid item xs={12}>
                <TextField
                  id="customerName"
                  label="Customer Name"
                  variant="outlined"
                  size="small"
                  value={oldCustomerData.name}
                  required
                  autoFocus
                  onChange={setOldCustomerName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="customerGuid"
                  label="Customer GUID"
                  variant="outlined"
                  size="small"
                  value={oldCustomerData.id}
                  required
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label="Should the customer be enabled?"
                  control={
                    <Checkbox
                      id="customerEnable"
                      checked={oldCustomerData.enable}
                      onChange={handleOldEnable}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} justifyContent="flex-end" direction="row">
                  <Button
                    onClick={() => setConfirmDelete(true)}
                    variant="contained"
                    color="error"
                    endIcon={<Delete />}>
                    Delete
                  </Button>
                  <Button
                    onClick={handleEditCustomer}
                    variant="contained"
                    color="success"
                    endIcon={<Send />}>
                    Update
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditCustomerForm;
