import { Alert, Button, Card, Chip, CircularProgress, Stack, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useGetServiceInfo } from 'src/features/serviceConfiguration/api';
import { ServiceInfo, Site } from 'src/types/site';
import { useGetHeaders, useUserContext } from 'src/contexts/userContext';
import axios, { AxiosRequestHeaders } from 'axios';
import { baseUrl } from '../../features/serviceConfiguration/api';

export interface TestWeeklyMenuResult {
  providerResults: ProviderResult[];
  exception: string;
}

export interface ProviderResult {
  providerName: string;
  exception: string;
  restaurantResults: TestWeeklyMenuRestaurantResult[];
}

export interface TestWeeklyMenuRestaurantResult {
  restaurantName: string;
  numMenus: string;
}

const WeeklyMenuTest = () => {
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(
    'fbc24e3d-7c72-4540-b6e2-21d4324db05c',
  );
  const { currentSite } = useUserContext();
  const headers = useGetHeaders('access_token') as AxiosRequestHeaders;

  const [testResult, setTestResult] = useState<TestWeeklyMenuResult>();
  const [testRunning, setTestRunning] = useState<boolean>(false);

  const runTest = async () => {
    setTestRunning(true);

    try {
      const response = await axios.post<TestWeeklyMenuResult>(
        `${baseUrl}${serviceInfo?.basePath}/food/WeeklyLunch/${(currentSite as Site).id}/test`,
        {},
        { headers },
      );

      setTestResult(response.data);
    } finally {
      setTestRunning(false);
    }
  };

  return (
    <>
      {testRunning && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Stack direction="row" spacing={3} alignItems="center">
            <CircularProgress />
            <h3>Test running</h3>
          </Stack>
        </Paper>
      )}

      {testResult && (
        <Paper variant="elevation" elevation={0}>
          {testResult.exception && (
            <Alert color="error" sx={{ m: 2 }}>
              <h2>Global error ocurred</h2>
              <p>{testResult.exception}</p>
            </Alert>
          )}

          {!!testResult.providerResults?.length &&
            testResult.providerResults.map((x, i) => (
              <Card key={`result-${i}`} sx={{ p: 2, mb: 1 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip color="secondary" label={x.providerName} />
                  {x.restaurantResults?.map((r) => (
                    <h3 key={r.restaurantName}>
                      {r.restaurantName} - {r.numMenus} menus
                    </h3>
                  ))}
                </Stack>

                {x.exception && (
                  <Alert color="error" sx={{ m: 2 }}>
                    <p>{x.exception}</p>
                  </Alert>
                )}
              </Card>
            ))}
        </Paper>
      )}

      <Button onClick={runTest} disabled={testRunning} variant="contained" color="success">
        {testResult ? 'Run test again' : 'Run test to get started'}
      </Button>
    </>
  );
};

export default WeeklyMenuTest;
