import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/logo.svg';

const LogoWrapper = () => {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Button component={Link} to="/">
        <img src={logo} width="40" alt="logo" />
        <Typography
          variant="h5"
          component="h1"
          sx={{ ml: 2, color: theme.whiteColor, textTransform: 'none', fontWeight: 'bold' }}>
          Simply by Coor admin
        </Typography>
      </Button>
    </Box>
  );
};

export default LogoWrapper;
