import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Button,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import { VisuallyHiddenInput } from './Locations';
import { ChangeEvent, useRef, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  ImportLocationsResponse,
  useImportLocationMutation,
  useInvaliateLocationsQuery,
} from './api';
import { useSnackbar } from 'notistack';

interface ImportProps {
  showFileUpload: boolean;
  setShowFileUpload: (value: boolean) => void;
}

const Import = ({ showFileUpload, setShowFileUpload }: ImportProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const invalidateQuery = useInvaliateLocationsQuery();
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const [uploadText, setUploadText] = useState('Upload');
  const [showIsLoading, setShowIsLoading] = useState(false);
  const [fileContents, setFileContents] = useState<string>('');
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [importFileUrl, setImportFileUrl] = useState('');
  const [importPreviewResult, setImportPreviewResult] = useState<ImportLocationsResponse | null>(
    null,
  );

  const importMutation = useImportLocationMutation();

  const handleFileUpload = async (e: React.MouseEvent) => {
    e.preventDefault();
    setShowIsLoading(true);
    setImportPreviewResult(null);
    if (fileContents.length > 0) {
      setUploadDisabled(true);
      try {
        const res = await importMutation.mutateAsync({
          data: fileContents,
          isPreview: false,
        });
        if (res.status === 200) {
          setImportPreviewResult(null);
          setUploadText('Upload');
          showSnack('File imported successfully', { variant: 'success' });
          invalidateQuery();
          setShowFileUpload(false);
        }
      } catch (error) {
        showSnack('Error importing file', { variant: 'error' });
      } finally {
        setFileContents('');
        setUploadText('Upload');
        setShowIsLoading(false);
      }
    } else {
      setImportFileUrl('');
      if (ref.current) {
        const file = ref.current.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = async () => {
            setFileContents(reader.result as string);
            try {
              const res = await importMutation.mutateAsync({
                data: (reader.result as string) || '',
                isPreview: true,
              });
              if (res.status === 200) {
                setImportPreviewResult(res.data);
                setUploadText('Save');
                setUploadDisabled(false);
              }
            } catch (error) {
              showSnack('Error importing file', { variant: 'error' });
              setUploadText('Upload');
              setUploadDisabled(true);
            } finally {
              setShowIsLoading(false);
            }
          };
          reader.readAsText(file, 'UTF-8');
        }
      }

      setUploadDisabled(true);
    }
  };

  const handleHiddenInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileContents('');
    setUploadText('Upload');
    setImportFileUrl(e.target.value);
    setUploadDisabled(false);
  };

  return (
    <Dialog
      maxWidth="xl"
      open={showFileUpload}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-discription">
      <DialogTitle id="alert-dialog-title">Chose file</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the file you want to import locations from, all data will be replaced when saving.
        </Typography>
        <Paper style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '20px' }}>
          <Button component="label" color="info" variant="contained" startIcon={<UploadFileIcon />}>
            Select file
            <VisuallyHiddenInput
              accept=".csv, .txt"
              type="file"
              ref={ref}
              onChange={handleHiddenInputChange}
            />
          </Button>
          <p>{importFileUrl}</p>
        </Paper>
        {showIsLoading && (
          <Paper sx={{ marginTop: 2, p: 1 }} elevation={3}>
            <p>Uploading file...</p>
            <LinearProgress />
          </Paper>
        )}
        {importPreviewResult && (
          <Paper sx={{ marginTop: 2, p: 1 }} elevation={3}>
            <p>
              The file can be processed, the following will change:
              <br />
              Imported rows: {importPreviewResult.numImportedRows}
              <br />
              Changed rows: {importPreviewResult.numNewLocations}
              <br />
              Import depth: {importPreviewResult.greatestDepth}
            </p>
          </Paper>
        )}

        <Paper sx={{ marginTop: 2, p: 1 }} elevation={3}>
          <p>
            The expected input format is a CSV file using <b>;</b> for seperation with a header row.
            It should have the following columns:
          </p>
          <p style={{ fontWeight: '600', fontFamily: 'monospace' }}>
            ParentId;LocationId;Description;Type;ExternalSiteId;DescriptionRequired;IsSelectable;IsHidden;MetadataIds
          </p>
          <p>
            Where MetadataIds is a list of guids separated by <b>,</b>
          </p>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setFileContents('');
            setImportPreviewResult(null);
            setShowFileUpload(false);
          }}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={handleFileUpload}
          disabled={uploadDisabled}>
          {uploadText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Import;
