import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Loader from '../components/Loader';
import { useUserContext } from '../contexts/userContext';
import Contracts from './Contracts';
import Customers from './Customers';
import Home from './Home';
import ServiceConfiguration from './ServiceConfiguration';
import Sites from './Sites';
import PageNotFound from '../components/PageNotFound';

const Main = () => {
  const { user, userManager } = useUserContext();
  const location = useLocation();
  useEffect(() => {
    if (!user) {
      void userManager.signinRedirect({ state: { localPath: location.pathname } });
    }
  }, [user]);

  if (!user) {
    return <Loader />;
  }
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="customers/*" element={<Customers />} />
        <Route path="contracts/" element={<Contracts />} />
        <Route path="contracts/:id" element={<Contracts />} />
        <Route path="sites/new" element={<Sites path="new" />} />
        <Route path="sites/:id" element={<Sites path="edit" />} />
        <Route path="services/:serviceId/:sub/*" element={<ServiceConfiguration />} />
        <Route path="services/:serviceId" element={<ServiceConfiguration />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Main;
