import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, Chip, Paper, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';

interface AddressPatternEntry {
  type: 'single' | 'range';
  value: string;
}

const parseIpRestrictionPattern = (pattern: string) => {
  if (!pattern) {
    return {
      isValid: true,
      addresses: [],
    };
  }

  const addressIsValid = (address: string) =>
    !!address.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)?.length;

  const addresses: AddressPatternEntry[] = [];
  const lines = pattern.split('\n');

  // eslint-disable-next-line no-restricted-syntax
  for (const line of lines) {
    const lineParts = line.trim().split('-');
    if (lineParts.length > 2 || !lineParts.every(addressIsValid)) {
      return {
        isValid: false,
        addresses,
      };
    }

    if (lineParts.length === 2) {
      addresses.push({
        type: 'range',
        value: `${lineParts[0]} - ${lineParts[1]}`,
      });
    } else {
      addresses.push({
        type: 'single',
        value: lineParts[0],
      });
    }
  }

  return {
    isValid: true,
    addresses,
  };
};

export interface IpRestrictionFormProps {
  ipRestrictionPattern?: string;
  onSave: (ipRestrictionPattern: string) => void;
}

const IpRestrictionForm = ({
  ipRestrictionPattern: ipRestrictionPatternInitial,
  onSave,
}: IpRestrictionFormProps) => {
  const [ipRestrictionPattern, setIpRestrictionPattern] = useState<string>(
    ipRestrictionPatternInitial ?? '',
  );

  const { isValid, addresses } = useMemo(
    () => parseIpRestrictionPattern(ipRestrictionPattern),
    [ipRestrictionPattern],
  );

  const { enqueueSnackbar: showSnack } = useSnackbar();

  const handleSave = () => {
    onSave(ipRestrictionPattern);
    showSnack('Updated ip restriction data, you can now save your changes to the server!', {
      variant: 'success',
    });
  };

  return (
    <>
      <TextField
        sx={{ marginTop: 2 }}
        multiline
        minRows={5}
        fullWidth
        size="small"
        error={!isValid}
        value={ipRestrictionPattern}
        id="ipRestrictionPattern"
        label="IP address restriction pattern"
        onChange={(e) => setIpRestrictionPattern(e.target.value)}
        inputProps={{
          autoCorrect: 'false',
          autoComplete: 'off',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '0.2rem',
          marginTop: 1,
        }}>
        {addresses.map((x) => (
          <Chip
            key={x.value}
            label={x.value}
            variant="filled"
            color={x.type === 'range' ? 'primary' : 'secondary'}
          />
        ))}
      </Box>

      <Paper sx={{ marginTop: 2, p: 1 }} elevation={3}>
        <p>
          The pattern consists of IP address ranges or single addresses separated by{' '}
          <b>new lines</b>.
        </p>
        <p>
          A range is written as <i>startIp</i>
          <b>-</b>
          <i>endIp</i>, example:
        </p>
        <pre>92.45.34.5-92.45.12.67</pre>
        <p>Single IP addresses are entered as is, example:</p>
        <pre>95.32.23.54</pre>
      </Paper>

      <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="success"
          variant="contained"
          disabled={!isValid}
          onClick={handleSave}
          endIcon={<SaveAltIcon />}>
          Save IP Address restrictions
        </Button>
      </Box>
    </>
  );
};

export default IpRestrictionForm;
