import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getCustomers } from '../../Customers/api';
import { getCustomerContract } from '../api';
import { Customer } from '/src/types/customer';

export const ContractsList = ({ customerId }: { customerId: string }) => {
  const { data, error, isLoading } = getCustomerContract(customerId);
  return (
    <List dense>
      {isLoading && <Skeleton />}
      {!isLoading &&
        !error &&
        data?.map((contract) => {
          return (
            <ListItemButton key={contract.id} component={Link} to={`/contracts/${contract.id}`}>
              <ListItemText>{contract.description}</ListItemText>
            </ListItemButton>
          );
        })}
    </List>
  );
};

const ViewContractsForm = () => {
  const { data: customers, isLoading, error } = getCustomers();
  const [fCustomers, setfCustomers] = useState<Customer[]>(customers as Customer[]);
  const [filter, setFilter] = useState<string>('');
  const [expanded, setExpanded] = useState<string>('');
  const handleExpanded =
    (panel: string) => (_: React.SyntheticEvent<Element, Event>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '');
    };

  const handleCustomerFilter = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;
    setFilter(value);
    const fc: Customer[] = customers?.filter((d) =>
      d.name.toLowerCase().startsWith(value.toLowerCase()),
    ) as Customer[];
    setfCustomers(fc);
  };

  useEffect(() => {
    if (customers) {
      setfCustomers(customers);
    }
  }, [customers]);

  return (
    <Box component="form">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Available customers and contracts
      </Typography>
      {(error as boolean) && (
        <Stack>
          <Alert severity="warning">
            <AlertTitle>Error</AlertTitle>
            Could not fetch customers
          </Alert>
        </Stack>
      )}
      <Stack justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          component={Link}
          color="success"
          to="/contracts/new"
          variant="contained"
          endIcon={<AddIcon />}>
          New contract
        </Button>
      </Stack>
      <Stack>
        <FormControl fullWidth variant="standard" sx={{ mt: 1, mb: 2 }} error={false}>
          <TextField
            label="Filter customers"
            size="small"
            value={filter}
            onChange={handleCustomerFilter}
          />
        </FormControl>
      </Stack>
      {isLoading && !error && <Skeleton />}
      {fCustomers &&
        Array.isArray(fCustomers) &&
        fCustomers.map((d) => {
          return (
            <Accordion
              key={d.id}
              disableGutters
              expanded={d.id === expanded}
              onChange={handleExpanded(d.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${d.id}-content`}
                id={`panel-${d.id}-header`}>
                <Typography>{d.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expanded === d.id ? <ContractsList customerId={d.id} /> : ''}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};
export default ViewContractsForm;
