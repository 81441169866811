/* eslint react/prop-types: 0 */
import { AddLocation, Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import LocationChooser, { LatLng, LocationAddress } from '../../../components/LocationChooser';
import { Location } from '/src/types/site';

interface LocationFormProps {
  values: Location;
  onValueChange: (value: Location) => void;
}

const LocationForm = ({ values, onValueChange }: LocationFormProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { longitude, latitude, street, zip, country, municipality, city } = values || {
    country: 'Sweden',
  };

  const changePropertyValue = (key: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValueChange({ ...values, [key]: e.target.value });
    };
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const toggleLocationChooser = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenDialog(!openDialog);
  };

  const handleMarkerPlacement = (_latLng: LatLng, parts: LocationAddress) => {
    const tmpObj = {
      ...values,
      ..._latLng,
      street: `${parts.street} ${parts.number}`,
      country: parts.country || '',
      zip: parts.zipcode || '',
      city: parts.town || '',
      municipality: parts.lan || '',
    };
    onValueChange(tmpObj);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleClose}>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>Select location on map</Grid>
            <Grid item>
              <IconButton onClick={() => setOpenDialog(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <LocationChooser
            latitude={parseFloat(latitude as string)}
            longitude={parseFloat(longitude as string)}
            onPlaceMarker={handleMarkerPlacement}
          />
          <Grid container direction="row" sx={{ mt: 2 }}>
            <Grid item xs={12} md={6} sx={{ pr: 1 }}>
              <TextField fullWidth value={latitude ?? ''} label="Latitude" />
            </Grid>
            <Grid item xs={12} md={6} sx={{ pl: 1 }}>
              <TextField fullWidth value={longitude ?? ''} label="Longitude" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={toggleLocationChooser} color="warning" variant="outlined">
            Cancel
          </Button>
          <Button onClick={toggleLocationChooser} color="success" variant="contained">
            Set location
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h6" sx={{ my: 1.5 }}>
        Select GPS location for site
      </Typography>
      <Grid
        container
        direction="row"
        sx={{
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: 1,
          p: 1,
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
        }}>
        <Grid item xs={6} md={4}>
          <Typography variant="body1" sx={{ my: 1 }}>
            Lat: {latitude ?? ''}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="body1" sx={{ my: 1 }}>
            Lng: {longitude ?? ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            component="span"
            variant="outlined"
            onClick={toggleLocationChooser}
            startIcon={<AddLocation />}>
            Select location
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ my: 1 }}>
        Address
      </Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={9}>
          <TextField
            size="small"
            value={street ?? ''}
            fullWidth
            required
            label="Street address"
            onChange={(e) => changePropertyValue('street')(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            value={zip ?? ''}
            fullWidth
            required
            label="Zip code"
            onChange={changePropertyValue('zip')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            value={city ?? ''}
            fullWidth
            required
            label="City"
            onChange={changePropertyValue('city')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            value={municipality ?? ''}
            fullWidth
            label="Municipality"
            onChange={changePropertyValue('municipality')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            value={country ?? 'Sweden'}
            fullWidth
            required
            label="Country"
            onChange={changePropertyValue('country')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LocationForm;
