import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import 'babel-polyfill';
import './index.css';

// if (process.env.NODE_ENV !== 'development' && window.location.protocol === 'http:') {
//   window.location = document.URL.replace('http://', 'https://');
// }

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;

const root = createRoot(container);
root.render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
