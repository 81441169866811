/* eslint react/prop-types: 0 */
import {
  Checkbox,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Service } from '/src/types/site';
import { UseQueryResult } from 'react-query';
import React from 'react';
export interface ServiceFormProps {
  values: Service[];
  chooseAble: UseQueryResult<Service[]>;
  onValueChange: (services: Service[]) => void;
  emptyText?: string;
}

const ServicesForm = ({ values, chooseAble, onValueChange, emptyText }: ServiceFormProps) => {
  // const [services, setServices] = useState([]);

  const handleCheckboxChange = (value: Service) => {
    const currentIndex = values.indexOf(value);
    const tmpServices = [...values];
    if (currentIndex === -1) {
      tmpServices.push(value);
    } else {
      tmpServices.splice(currentIndex, 1);
    }
    onValueChange(tmpServices);
  };

  // useEffect(() => {
  //   if (chooseAble) {
  //     setServices(chooseAble);
  //   }
  // }, [chooseAble]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Select active services
        </Typography>
        <Grid container sx={{ width: '100%' }}>
          {chooseAble?.isLoading
            ? [1, 2, 3].map((d) => {
                return (
                  <Grid item key={d} xs={6}>
                    <ListItem>
                      <Skeleton sx={{ width: '100%' }} />
                    </ListItem>
                  </Grid>
                );
              })
            : (chooseAble?.data as Service[])?.map((service) => {
                const labelId = `checkbox-list-label-${service.id}`;

                return (
                  <Grid item key={service.id} xs={6}>
                    <ListItem key={service.id} disablePadding>
                      <ListItemButton
                        role={undefined}
                        dense
                        onClick={() => handleCheckboxChange(service.id as unknown as Service)}>
                        <ListItemIcon>
                          <Checkbox
                            value={service.id}
                            edge="start"
                            checked={values.indexOf(service.id as unknown as Service) > -1}
                            tabIndex={-1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={service.name} />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                );
              })}
          {chooseAble && !chooseAble.isLoading && chooseAble.data === undefined && (
            <Typography>{emptyText}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicesForm;
