import React, { useEffect } from 'react';

import AccessDenied from '../components/AccessDenied';

const AccessDeniedRoute = () => {
  useEffect(() => {
    document.title = 'Access denied | Simply By Coor';
  }, []);

  return <AccessDenied />;
};

export default AccessDeniedRoute;
