import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../contexts/userContext';
import { User } from 'oidc-client-ts';

const SignInCallback = () => {
  const { user, userManager, setUser } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      userManager
        .signinRedirectCallback()
        .then((res: User) => {
          setUser(res);
          if (res.state) {
            navigate((res.state as { localPath?: string }).localPath as string);
          }
        })
        .catch((error: Error) => {
          throw error;
        });
    }
  });

  return null;
};

export default SignInCallback;
