import React from 'react';
import { Link } from 'react-router-dom';

import Page from './Page';

const PageNotFound = () => {
  return (
    <Page>
      <h2>Page Not Found</h2>
      <p>
        The page you were looking for doesn&apos;t exist. Please head back to the{' '}
        <Link to="/">home page</Link>.
      </p>
    </Page>
  );
};

export default PageNotFound;
