import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { MappedQR } from './IQR';
import { useRowProvider } from './Row';

interface QRCustomURIProps {
  data: MappedQR;
}

const QRCustomURI = ({ data }: QRCustomURIProps) => {
  const [customUrl, setCustomUrl] = useState<string>(data.customURI);
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { edit, setHasEdit } = useRowProvider();
  const onCopy = () => {
    navigator.clipboard
      .writeText(data.customURI)
      .then(() => showSnack('Url copied to clipboard.', { variant: 'success' }))
      .catch(() =>
        showSnack('Could not copy url to clipboard, copy manually instead.', {
          variant: 'warning',
        }),
      );
  };

  if (!edit) {
    return (
      <Box
        flexGrow={1}
        sx={{
          whiteSpace: 'wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
        }}>
        {data.customURI}
      </Box>
    );
  }
  return (
    <Box flexGrow={1}>
      <TextField
        value={customUrl}
        onChange={(e) => {
          setCustomUrl(e.target.value);
          setHasEdit(true);
          data.customURI = e.target.value;
        }}
        size="small"
        sx={{ width: '100%' }}
        InputProps={{
          endAdornment: navigator.clipboard && (
            <InputAdornment position="end">
              <IconButton edge="end" color="primary" onClick={onCopy}>
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}></TextField>
    </Box>
  );
};

export default QRCustomURI;
