import { IdTokenClaims, User, UserManager } from 'oidc-client-ts';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Site } from '../types/site';

export interface IUserContext {
  setCurrentSite: (site: Site | string) => void;
  signoutRedirect: (e: unknown) => void;
  setUser: (user: User) => void;
  userManager: UserManager;
  currentSite: Site | string | null;
  hasAccess: boolean;
  user: User;
  [key: string]: unknown;
}

export const UserContext = createContext<IUserContext | null>(null);
const matchAgains = 'Myworkplace Administrator';
const keyName: keyof IdTokenClaims = 'http://schemas.identity.coor.com/identity/claims/roles';

const { protocol } = window.location;
const { hostname } = window.location;
const port = window.location.port ? `:${window.location.port}` : '';
const protocolAndHost = `${protocol}//${hostname}${port}`;

const signoutRedirect = (userManager: UserManager) => {
  const auth = process.env.REACT_APP_AUTH_AUTHORITY;
  const returnTo = encodeURI(protocolAndHost);
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  userManager
    .removeUser()
    .then(() =>
      window.location.assign(
        `${auth}/v2/logout?returnTo=${returnTo}/signedout&client_id=${clientId}`,
      ),
    )
    .catch(() => {
      throw new Error('Error in logging out.');
    });
};

function getLocalStorage<T>(item: string) {
  const storedValue = localStorage.getItem(item) || null;

  if (storedValue !== 'undefined') {
    return JSON.parse(storedValue as string) as T;
  }
  return null;
}

export const useGetHeaders = <T,>(type: keyof User = 'access_token') => {
  const { user } = useUserContext();
  if (!user) {
    return null;
  }
  return {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${user[type] as string}`,
  } as T;
};

export const getUserAccess = (user: User) => {
  if (user?.profile) {
    const { [keyName]: roles } = user?.profile || [];
    if (!roles || (roles as string[]).indexOf(matchAgains) < 0) {
      return false;
    }
  }
  return true;
};

export const useUserContext = () => {
  const ctx = useContext(UserContext);
  if (ctx === null) {
    throw new Error('userContext must be usen within UserContextProvider');
  }
  return ctx;
};

export const UserProvider = ({
  userManager,
  children,
}: {
  userManager: UserManager;
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const s = getLocalStorage<Site>('currentSite');
  const [currentSite, setCurrentSite] = useState<Site | string | null>(s);

  const initialContext: IUserContext = {
    userManager,
    user: user as User,
    setUser,
    currentSite,
    hasAccess: getUserAccess(user as User),
    setCurrentSite: (site: Site | string) => {
      setCurrentSite(site);
      if (!site) return localStorage.removeItem('currentSite');
      return localStorage.setItem('currentSite', JSON.stringify(site));
    },
    signoutRedirect: () => signoutRedirect(userManager),
  };
  const loc = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (loc.pathname !== '/accessdenied') {
      const access = getUserAccess(user as User);
      if (!access) {
        nav('/accessdenied');
      }
    }
  }, [user, loc.pathname]);
  return <UserContext.Provider value={initialContext}>{children}</UserContext.Provider>;
};

export default UserContext;
