import axios, { AxiosRequestHeaders } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { useGetHeaders } from '../../contexts/userContext';
import { Customer } from '../../types/customer';

const baseUrl = process.env.REACT_APP_BAM_URL;

export const getCustomers = () => {
  const headers = useGetHeaders() as AxiosRequestHeaders;

  return useQuery<Customer[]>(
    'getCustomers',
    async () => {
      const req = await axios.get<Customer[]>(`${baseUrl}/Customer`, { headers });
      return req.data;
    },
    { enabled: headers !== null },
  );
};
export const addCustomer = () => {
  const headers = useGetHeaders() as AxiosRequestHeaders;

  return useMutation(async (data: Customer) => {
    return axios.post(`${baseUrl}/Customer`, data, {
      headers,
    });
  });
};

export const editCustomer = () => {
  const headers = useGetHeaders() as AxiosRequestHeaders;
  return useMutation(async (data: Customer) => {
    return axios.put<Customer>(`${baseUrl}/Customer/${data.id}`, data, {
      headers,
    });
  });
};

export const deleteCustomer = () => {
  const headers = useGetHeaders() as AxiosRequestHeaders;

  return useMutation(async (id: string) => {
    return axios.delete<{ customerId: string; status: string }>(`${baseUrl}/Customer/${id}`, {
      headers,
    });
  });
};

export const doPublishCustomer = () => {
  const headers = useGetHeaders() as AxiosRequestHeaders;

  return useMutation(async () => {
    return axios.post(`${baseUrl}/customer/publish`, {}, { headers });
  });
};
export default { getCustomers, addCustomer, editCustomer, deleteCustomer };
