import { useContext, useEffect, useState } from 'react';
import { SchemaComponent } from '/src/types/schema';
import AddIcon from '@mui/icons-material/Add';
import { Box, Stack, FormControl, TextField, InputLabel, Button, Typography } from '@mui/material';

import FormContext from '../../../../contexts/FormContext';
import MetadataForm from './MetadataForm';
import Collapsible from '../../Collapsible';

interface SRMetadataContent {
  locale: string;
  title: string;
  value: string;
}
export interface SRMetaData {
  id: string | null;
  name: string | null;
  translations: SRMetadataContent[];
}

interface MetadataProps {
  id: string;
  comp: SchemaComponent;
  onChange: () => void;
}

const Metadata = ({ id, comp }: MetadataProps) => {
  const { handleChange } = useContext(FormContext);
  const [localValue, setLocalValue] = useState<SRMetaData[]>((comp.value as SRMetaData[]) || []);

  const handleDeleteMetadata = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    const tmpData = [...localValue];
    tmpData.splice(index, 1);
    setLocalValue(tmpData);
    handleChange(id, tmpData);
  };
  const onNameChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const tmpData = [...localValue];
    tmpData[index] = { ...tmpData[index], name: e.target.value };
    setLocalValue(tmpData);
    handleChange(id, tmpData);
  };

  const onValueChange = (index: number, lang: string, valueType: string) => (v: string) => {
    const tmpData = [...localValue];
    const currentEditIndex = tmpData[index].translations.findIndex((t) => t.locale === lang);
    if (currentEditIndex === -1) {
      if (valueType === 'title') {
        tmpData[index].translations.push({ locale: lang, title: v, value: '' });
      } else {
        tmpData[index].translations.push({ locale: lang, title: '', value: v });
      }
    } else {
      tmpData[index].translations[currentEditIndex] = {
        ...tmpData[index].translations[currentEditIndex],
        [valueType]: v || '',
      };
    }
    handleChange(id, tmpData);
    setLocalValue(tmpData);
  };

  const handleAddMetadata = (e: React.MouseEvent) => {
    e.preventDefault();
    const tmpData = [...localValue];
    tmpData.push({ id: null, name: '', translations: [] });
    setLocalValue(tmpData);
    handleChange(id, tmpData);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', 'light');
  }, []);

  return (
    <>
      <Typography component="h3" variant="h6">
        Service Request Metadata configuration
      </Typography>
      <Box>
        {localValue.map((md, mdIndex) => {
          return (
            <Box
              sx={{
                my: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: '5px',
                padding: '2px',
              }}
              key={md.id}>
              <Collapsible
                open={false}
                sx={{ flex: '1' }}
                summary={
                  <Typography component="span" variant="button" sx={{ flex: '1 1 auto' }}>
                    {md.name || 'Name:'}
                  </Typography>
                }>
                <Box sx={{ pl: '1.5rem' }}>
                  <InputLabel>Name</InputLabel>
                  <FormControl>
                    <TextField
                      size="small"
                      value={md.name}
                      onChange={onNameChange(mdIndex)}
                      required
                    />
                  </FormControl>
                </Box>

                <MetadataForm
                  mdIndex={mdIndex}
                  md={md}
                  handleDeleteMetadata={handleDeleteMetadata}
                  onValueChange={onValueChange}
                />
              </Collapsible>
            </Box>
          );
        })}
      </Box>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        sx={{ mt: 1, pt: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Button
          onClick={handleAddMetadata}
          variant="contained"
          color="info"
          startIcon={<AddIcon />}>
          Add Metadata
        </Button>
      </Stack>
    </>
  );
};

export default Metadata;
