import { Autocomplete, Box, FormControl, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../contexts/userContext';
import { useGetSites } from '../features/Sites/api';
import { Site } from '../types/site';

interface AutoCompleteValues {
  label: string;
  value: string;
}

const SiteChanger = () => {
  const emptySite = {
    label: 'Select a site',
    value: '-1',
  };
  const { currentSite, setCurrentSite } = useUserContext();
  const [lCurrentSite, setLCurrentSite] = useState<AutoCompleteValues>(emptySite);
  const [lSites, setLSites] = useState<AutoCompleteValues[]>([emptySite]);
  const { data, isLoading, error } = useGetSites();
  const navigate = useNavigate();

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: AutoCompleteValues | null,
  ) => {
    const site = data?.find((s) => s.id === value?.value);
    if (!site) {
      setCurrentSite('-1');
      navigate(`/sites/new`);
    } else {
      navigate(`/sites/${site.id}`);
    }
    setCurrentSite(site!);
  };

  useEffect(() => {
    const d = data?.map((s) => {
      return { label: s.name, value: s.id };
    });
    setLSites(d as AutoCompleteValues[]);
    if (currentSite && data) {
      const cs = data.find((s) => s.id === (currentSite as Site).id);
      if (cs) {
        setLCurrentSite({ label: cs.name, value: cs.id! });
      } else {
        setLCurrentSite(emptySite);
      }
    }
  }, [data, currentSite]);

  return error ? (
    <p>Error fetching sites</p>
  ) : (
    <Box sx={{ minWidth: 120, px: 2, mt: 2 }}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <FormControl fullWidth size="small">
          {lSites && lSites.length > 0 && (
            <Autocomplete
              disablePortal
              size="small"
              value={lCurrentSite}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              filterOptions={(s, d) => {
                const a = s.filter((c) => {
                  if (!d.inputValue) {
                    return c;
                  }
                  return c.label.toLowerCase().includes(d.inputValue.toLowerCase());
                });
                return a;
              }}
              options={lSites}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} label="Select a site" />}
              fullWidth
              id="siteChangerSelect"></Autocomplete>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default SiteChanger;
