import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';

import { FormContext } from '../../contexts/FormContext';
import { SchemaComponent, SchemaComponentWithKey } from '../../types/schema';
import { ComponentBase } from './ComponentChooser';
import { OptionalTooltip } from '../OptionalTooltip';

interface SchemaComponentWithKeyLocal extends SchemaComponent {
  data: SchemaComponentWithKey[];
}

export interface SelectBoxProps extends ComponentBase {
  comp: SchemaComponentWithKeyLocal;
}

const SelectBox = ({ id, comp, required }: SelectBoxProps) => {
  const [chosenValue, setChosenValue] = useState<string>('');
  const { handleChange } = useContext(FormContext);
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const selVal = comp?.data?.filter((sl) => sl.key === value);
    if (Array.isArray(selVal)) {
      setChosenValue(value);
      handleChange(id, selVal);
    }
  };
  useEffect(() => {
    const orgVal = comp.value as SchemaComponentWithKey[];
    const orgData = comp.data;
    const val = (orgVal?.length && orgVal[0].key) || (orgData?.length && orgData[0]?.key) || '';

    const selVal = orgData?.filter((sl) => sl.key === val);
    if (Array.isArray(selVal) && selVal.length > 0) {
      setChosenValue(val);
      handleChange(id, selVal);
    }
  }, [comp]);

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <FormControl required={required} fullWidth>
          <TextField
            select
            SelectProps={{
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            label={comp.title}
            size="small"
            id={id}
            onChange={onHandleChange}
            name={id}
            value={chosenValue}
            required={required}>
            {comp.data &&
              comp.data.map((kv, index) => {
                const key = `selectvalue-${index}-${kv.key}`;
                return (
                  <MenuItem key={key} value={kv.key}>
                    {kv.value as string}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
      </Box>
    </OptionalTooltip>
  );
};

export default SelectBox;
