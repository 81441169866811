const useCopy = () => {
  return async (stringData: string) => {
    if (window.ClipboardItem) {
      const type = 'text/plain';
      const blob = new Blob([stringData], { type });
      const data = [new window.ClipboardItem({ [type]: blob })];

      await navigator.clipboard.write(data);
    } else {
      await navigator.clipboard.writeText(stringData);
    }
  };
};

export default useCopy;
