import React from 'react';
import Page, { Header } from './Page';
import styled from 'styled-components';

const P = styled.p`
  font-size: inherit;
  color: inherit;
  line-height: 1.5;

  & + & {
    margin-top: 1rem;
  }
`;

const AccessDenied = () => {
  return (
    <Page>
      <Header>Access denied</Header>
      <P>
        You do not have access to this site, please contact a administrator to help you with this.
      </P>
    </Page>
  );
};

export default AccessDenied;
