import React, { useEffect } from 'react';

import CustomerForm from '../features/Customers';

const CustomersRoute = () => {
  useEffect(() => {
    document.title = 'Customers | Simply by Coor';
  }, []);

  return <CustomerForm />;
};
export default CustomersRoute;
