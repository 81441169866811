import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Stack,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';

import {
  OnChangeValueType,
  SchemaComponent,
  SchemaComponentWithKey,
  SchemaObject,
} from '../../types/schema';
import ComponentChooser from './ComponentChooser';
import { FormContext, FormContextType } from '../../contexts/FormContext';

const PopoutContainer = ({
  onChange,
  row,
  components,
}: {
  onChange: (subKey: string) => (fullPath: string, value: OnChangeValueType) => void;
  row: SchemaObject;
  components: SchemaComponentWithKey[];
}) => {
  const [open, setOpen] = useState(false);
  const [localRow, setLocalRow] = useState(row);
  const { setFormIsDirty } = useContext<FormContextType>(FormContext);

  const popoutComponents = components.filter((x) => x.popout);
  if (popoutComponents.length === 0) {
    return null;
  }

  const onClose = () => {
    setOpen(false);

    popoutComponents.forEach(({ key }) => {
      onChange(key)(key, localRow[key] as SchemaComponent);
    });
  };

  return (
    <>
      <FormControl>
        <IconButton
          aria-label="Open additional properties"
          onClick={() => setOpen(true)}
          color="info"
          sx={{ whiteSpace: 'nowrap' }}>
          <OpenInNewIcon />
        </IconButton>
      </FormControl>

      <Dialog open={open} onClose={onClose} aria-labelledby="additional-dialog-title">
        <DialogTitle id="alert-dialog-title">Additional properties</DialogTitle>
        <DialogContent>
          {popoutComponents
            .filter((x) => x.type !== 'Hidden')
            .map((x) => (
              <FormContext.Provider
                key={x.key}
                value={{
                  handleChange: (_, value) => setLocalRow({ ...localRow, [x.key]: value }),
                  setFormIsDirty,
                  setDirty: () => {
                    /* empty */
                  },
                }}>
                <FormControl sx={{ flex: '1 1 auto', my: '0' }}>
                  <ComponentChooser
                    id={x.key}
                    comp={{ ...x, value: (localRow[x.key] as SchemaComponent) ?? '' }}
                    required={false}
                  />
                </FormControl>
              </FormContext.Provider>
            ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ObjectCollectionProps {
  id: string;
  comp: SchemaComponent;
}

const ObjectCollection = ({ id, comp }: ObjectCollectionProps) => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { handleChange, setFormIsDirty } = useContext<FormContextType>(FormContext);
  const [objects, setObjects] = useState<SchemaObject[]>([]);
  const [dirty, setDirty] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const isReadonly = comp?.readonly ?? false;

  const getDefaultObject = (): SchemaObject =>
    Object.fromEntries(comp?.components?.map((x) => [x.key, undefined]) ?? []);

  const deleteObject = () => {
    if (deleteIndex !== null) {
      const tmpObjects = [...objects];
      tmpObjects.splice(deleteIndex, 1);
      setObjects(tmpObjects);
      setDeleteIndex(null);
    }
    setFormIsDirty(true);
    setDirty(true);
    setShowConfirmDelete(false);
  };

  const addObjectRow = (e: React.MouseEvent) => {
    e.preventDefault();
    setDirty(true);
    setFormIsDirty(true);
    setObjects([...objects, getDefaultObject()]);
  };

  const onChange = (index: number, path: string) => (_: string, value: OnChangeValueType) => {
    const currentValue = objects[index][path];

    if (value !== currentValue) {
      setDirty(true);
      setFormIsDirty(true);
    }

    const tmpObjects = [...objects];
    tmpObjects[index][path] = value;
    setObjects(tmpObjects);
  };

  const confirmDeleteObject = (index: number) => () => {
    setShowConfirmDelete(true);
    setDeleteIndex(index);
  };

  const closeDialog = () => {
    setDeleteIndex(null);
    setShowConfirmDelete(false);
  };

  const saveRows = () => {
    setDirty(false);
    setFormIsDirty(false);
    handleChange(id, [...objects]);
    showSnack('Update complete, you can now save your changes to the server!', {
      variant: 'success',
    });
  };

  const dirtyStyle = dirty
    ? { backgroundColor: 'rgba(255, 0, 0, 0.05)', border: '1px solid #faa' }
    : {};

  useEffect(() => {
    setObjects([...(comp.value as Record<string, string>[])]);
  }, [comp.value]);

  return (
    <Card sx={{ ...dirtyStyle, my: 2 }} variant="outlined" key={id}>
      <CardContent>
        <Typography component="h3">{comp.title}</Typography>
        <Typography component="small" sx={{ display: 'block', fontSize: '0.75rem' }}>
          {comp.toolTip}
        </Typography>

        {objects.map((row, index) => {
          const key = `customer-${index}`;
          return (
            <Box sx={{ my: '5px', m: '5px' }} key={key}>
              <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                {comp.components
                  ?.filter((x) => x.type !== 'Hidden' && !x.popout)
                  ?.map((x) => (
                    <FormContext.Provider
                      key={x.key}
                      value={{
                        handleChange: onChange(index, x.key),
                        setFormIsDirty,
                        setDirty: () => {
                          /* empty */
                        },
                      }}>
                      <FormControl sx={{ flex: '1 1 auto', my: '0' }}>
                        <ComponentChooser
                          id={x.key}
                          comp={{ ...x, value: (row[x.key] as SchemaComponent) ?? '' }}
                          required={false}
                        />
                      </FormControl>
                    </FormContext.Provider>
                  ))}
                <PopoutContainer
                  onChange={(subKey: string) => onChange(index, subKey)}
                  row={row}
                  components={comp.components ?? []}
                />
                {!isReadonly && (
                  <FormControl
                    sx={{
                      flex: '0 1 auto',
                      justifyContent: 'center',
                      alignItems: 'center',
                      my: 0,
                      p: 0,
                    }}>
                    <IconButton
                      aria-label="Delete this row"
                      onClick={confirmDeleteObject(index)}
                      color="error"
                      defaultValue={index}
                      sx={{ ml: 1, m: 0, p: 0 }}>
                      <DeleteIcon />
                    </IconButton>
                  </FormControl>
                )}
              </Stack>
            </Box>
          );
        })}
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1, pt: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
          {!isReadonly ? (
            <Button onClick={addObjectRow} variant="contained" color="info" startIcon={<AddIcon />}>
              Add row
            </Button>
          ) : (
            <span>&nbsp;</span>
          )}

          <Button
            color="success"
            variant="contained"
            disabled={!dirty}
            onClick={saveRows}
            endIcon={<SaveAltIcon />}>
            Save
          </Button>
        </Stack>

        <Dialog
          open={showConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-discription">
          <DialogTitle id="alert-dialog-title">Delete row</DialogTitle>
          <DialogContent>Are you sure you want to delete this row?</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="outlined" color="warning" onClick={deleteObject}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default ObjectCollection;
