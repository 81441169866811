import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material';

interface ActionButtonProps extends ButtonProps {
  action: () => Promise<unknown>;
}

export const ActionButton = ({ action, ...rest }: ActionButtonProps) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      await action();
    } finally {
      setLoading(false);
    }
  };

  return <LoadingButton {...rest} loading={loading} onClick={onClick} />;
};
