import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ICategoryItem, MappedQR } from './IQR';
import Collapsible from '../../Collapsible';
import { SortBy, SortOrder, useSearchQrCode } from './api';
interface FilterPaneProps {
  onFilter: (filterData: MappedQR[]) => void;
  categoryList: ICategoryItem[];
  pageSize: number;
  sortBy: SortBy;
  sortOrder: SortOrder;
}

export type QRStatuses = 'assigned' | 'unassigned' | 'all';

const FilterPane = ({ onFilter, sortBy, sortOrder }: FilterPaneProps) => {
  const timeOut = 350;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout>();
  const [qrCodeFilter, setQrCodeFilter] = useState<string>('');
  const [auaValue, setAuaValue] = useState<QRStatuses>('all');
  const [filterValue, setFilterValue] = useState<string>('');
  const { data } = useSearchQrCode(1000, qrCodeFilter, auaValue, sortBy, sortOrder);

  const handleAuaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as QRStatuses;
    setAuaValue(val);
  };

  const filterCodes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.value;
    clearTimeout(debounceTimer);
    setDebounceTimer(undefined);
    setFilterValue(f);
    const t = setTimeout(() => {
      setQrCodeFilter(f);
    }, timeOut);
    setDebounceTimer(t);
  };

  useEffect(() => {
    if (data?.qrCodes) {
      onFilter(data?.qrCodes || []);
    }
  }, [data]);

  return (
    <Collapsible
      open={true}
      unmountOnExit={false}
      summary={
        <Typography variant="h6" component={'h6'} sx={{ mb: 1 }}>
          Filter
        </Typography>
      }>
      <Box sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, pb: 2 }}>
        <Stack direction={'row'} gap={3}>
          <Box>
            <InputLabel>QR-Code</InputLabel>
            <TextField
              value={filterValue}
              onChange={filterCodes}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  return false;
                }
                return true;
              }}
              size="small"></TextField>
          </Box>
          <Box>
            <InputLabel>Status</InputLabel>
            <RadioGroup aria-labelledby="" row value={auaValue} onChange={handleAuaChange}>
              <FormControlLabel value="assigned" control={<Radio />} label="Assigned" />
              <FormControlLabel value="unassigned" control={<Radio />} label="Unassigned" />
              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
          </Box>
          <Box>
            <InputLabel>&nbsp;</InputLabel>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setQrCodeFilter('');
                setAuaValue('all');
              }}>
              Reset
            </Button>
          </Box>
        </Stack>
      </Box>
    </Collapsible>
  );
};

export default FilterPane;
