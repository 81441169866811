import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const UL = styled.ul<{ $listyle?: string }>`
  padding: 1rem 2rem;
  list-style: ${({ $listyle }) => ($listyle ? $listyle : 'decimal')};
  & > li {
    padding: 0.5rem 0;
  }
`;

const Home = () => {
  useEffect(() => {
    document.title = 'Home | Simply by Coor';
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: '85rem', my: 0, mx: 'auto', p: [2.5, 2] }}>
      <Typography variant="h2" component="h2" sx={{ my: 2 }}>
        Simply by Coor | Admin Pages | 🛠️
      </Typography>
      <Card variant="outlined">
        <CardContent sx={{ p: 4 }}>
          <Typography component="p" variant="body1" sx={{ mb: 2 }}>
            Welcome to the Simply by Coor Admin Pages Overview! This short guide will provide you
            with an introduction to the main functionalities of our app's admin pages.
          </Typography>

          <UL $listyle="decimal">
            <li>
              Configuring Customers and Contracts: In the admin pages, you can easily configure
              customers and contracts. However, please ensure that all configurations align with
              Auth0. Double-check your settings to avoid potential issues.
            </li>
            <li>
              Creating New Sites: Once you have configured the customers and contracts, you can
              create new sites. To do so, you must have the customer and contract information
              properly set up. After that, you can add as many sites as needed for each customer,
              tailoring the features to their requirements.
            </li>
            <li>
              Configuring Site Modules: Each site can have multiple modules, which you'll need to
              configure based on the specific needs of the customer. Some of the most common modules
              include:
            </li>
          </UL>

          <UL $listyle="lower-alpha">
            <li>
              CMS (Content Management System): This module allows users to create, edit, and manage
              content such as news, events, and other updates. Essentially you create a Strapi
              instance. This instance is unqiue to the site you're creating. Go to the URL created
              to start addidng content.
            </li>
            <li>
              Service Requests: Users can submit and track service requests, ensuring smooth
              communication between the facilities team and site occupants. Check out the cool copy
              and import functionality to speed up your work.
            </li>
            <li>
              Find Space: This module is for finding available spaces, displaying weather data, and
              providing other location-based information.
            </li>
            <li>
              Food: Configure restaurant information and lunch menus, allowing users to view and
              plan their meal options on-site.
            </li>
            <li>
              Coor Parcel: Enable this feature to display information about incoming letters and
              parcels, keeping users informed about their deliveries.
            </li>
          </UL>
          <Typography component="p" variant="body1" sx={{ mb: 2 }}>
            Remember, each site's modules need to be properly configured to provide the best
            possible experience for your customers. Pay close attention to the details and
            double-check your work to ensure a seamless user experience.
          </Typography>

          <Typography component="p" variant="body1" sx={{ mb: 2 }}>
            With this guide, you should now have a basic understanding of the Simply by Coor admin
            pages and their functionalities. Good luck, and happy configuring!
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Home;
