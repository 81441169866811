import { Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import Page, { Header } from '../../components/Page';
import AddSiteForm from './components/AddSiteForm';
import EditSiteForm from './components/EditSiteForm';

const SitesPage = ({ path }: { path: string }) => {
  // eslint-disable-next-line
  const { id } = useParams();
  return (
    <Page>
      <Header>Sites</Header>
      <Card>
        <CardContent>
          {id && <EditSiteForm siteId={id} />}
          {path === 'new' && <AddSiteForm />}
        </CardContent>
      </Card>
    </Page>
  );
};

SitesPage.propTypes = {
  path: PropTypes.string,
};
export default SitesPage;
