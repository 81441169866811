import { TextField } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { useCallback, useState, memo, useEffect } from 'react';
import { Library } from '@googlemaps/js-api-loader';

const containerStyle = {
  width: '100%',
  height: '600px',
};

const center = {
  lat: 59.3293,
  lng: 16.0686,
};

const defaultOptions = {
  zoomControl: true,
  mapTypeControl: false,
  rotateControl: false,
  streetViewControl: false,
  scaleControl: false,
  fullscreenControl: false,
};

const libraries: Library[] = ['places'];
export interface LocationAddress {
  number?: string;
  street?: string;
  town?: string;
  lan?: string;
  country?: string;
  zipcode?: string;
}
export interface LatLng {
  longitude: number;
  latitude: number;
}

interface LocationChooserProps {
  onClick?: () => void;
  onPlaceMarker: (markerPosition: LatLng, parts: LocationAddress) => void;
  latitude: number;
  longitude: number;
  options?: Record<string, string>;
}
const LocationChooser = ({
  onClick,
  onPlaceMarker,
  latitude,
  longitude,
  options,
}: LocationChooserProps) => {
  const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });
  const useOptions = { ...defaultOptions, ...options };
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

  const onLoad = useCallback((_map: google.maps.Map) => {
    setMap(_map);
  }, []);

  const onLoadSearchBox = (ref: google.maps.places.SearchBox) => setSearchBox(ref);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const updateMarker = async (latLng: google.maps.LatLng) => {
    const markerPosResult: LatLng = { latitude: latLng.lat(), longitude: latLng.lng() };
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({ location: latLng });
    const { results } = response;
    const parts: LocationAddress = {};
    if (results?.length) {
      const [address] = results;
      address.address_components.forEach((p) => {
        p.types.forEach((t) => {
          if (t === 'street_number') {
            parts.number = p.short_name;
          } else if (t === 'route') {
            parts.street = p.short_name;
          } else if (t === 'postal_town') {
            parts.town = p.short_name;
          } else if (t === 'administrative_area_level_1') {
            parts.lan = p.short_name;
          } else if (t === 'country') {
            parts.country = p.long_name;
          } else if (t === 'postal_code') {
            parts.zipcode = p.short_name;
          }
        });
      });
    }

    onPlaceMarker(markerPosResult, parts);
    map?.panTo(latLng);
    // map.setZoom(18);
  };

  const placeMarker = (e: google.maps.MapMouseEvent) => {
    void updateMarker(e.latLng as google.maps.LatLng);
  };

  const onPlacesChanged = () => {
    const places = (
      searchBox as google.maps.places.SearchBox
    ).getPlaces() as google.maps.places.PlaceResult[];
    if (places.length > 0) {
      const place = places[0];
      const { location } = place.geometry as google.maps.places.PlaceGeometry;
      void updateMarker(location as google.maps.LatLng);
      map?.setZoom(18);
    }
  };

  useEffect(() => {
    if (latitude && longitude) {
      center.lat = latitude;
      center.lng = longitude;
    }
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={8}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={useOptions}
      onRightClick={placeMarker}
      onClick={onClick}>
      {Boolean(latitude) && <Marker position={{ lat: latitude, lng: longitude }} />}
      <StandaloneSearchBox onLoad={onLoadSearchBox} onPlacesChanged={onPlacesChanged}>
        <TextField
          size="small"
          id="textfield-box-search"
          label="Search"
          sx={{
            zIndex: 2000,
            width: 300,
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'white',
          }}
        />
      </StandaloneSearchBox>
    </GoogleMap>
  ) : null;
};

export default memo(LocationChooser);
