import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';

import { useUserContext } from '../contexts/userContext';
import CurrentUser from './CurrentUser';
import Logo from './Logo';
import Nav from './Nav';

const Header = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(true);
  const showDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <AppBar position="fixed" sx={{ flexGrow: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ px: 1 }} disableGutters>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={showDrawer}
            sx={{
              mx: 1,
            }}>
            <MenuIcon />
          </IconButton>
          <Logo />
          {user && user.profile && (
            <CurrentUser
              userName={user.profile.name as string}
              picture={user.profile.picture as string}
            />
          )}
        </Toolbar>
      </AppBar>
      <Nav open={open} />
    </>
  );
};

export default Header;
