import { useEffect } from 'react';

import BuildMatrix from '../components/BuildMatrix';

const BuildMatrixRoute = () => {
  useEffect(() => {
    document.title = 'Build matrix | Simply by Coor';
  }, []);

  return <BuildMatrix />;
};

export default BuildMatrixRoute;
