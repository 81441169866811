import { useNavigate } from 'react-router-dom';

import ServiceConfig from '../features/serviceConfiguration';
import { useUserContext } from '../contexts/userContext';

const ServiceConfiguration = () => {
  const { currentSite } = useUserContext();
  const navigate = useNavigate();
  if (!currentSite) {
    navigate('/');
  }
  return <ServiceConfig />;
};
export default ServiceConfiguration;
