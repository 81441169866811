import { Box, Stack, TablePagination } from '@mui/material';
import { PaginationProps } from './IQR';

const Pagination = ({
  count,
  pageSize,
  currentPage,
  onPageChange,
  onHandleRowChange,
  children,
}: PaginationProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box flex="1 1 auto">{children}</Box>
      <Box flex="0 1 auto">
        <TablePagination
          component="div"
          count={count}
          page={currentPage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={onHandleRowChange}
          onPageChange={onPageChange}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
    </Stack>
  );
};

export default Pagination;
