import { Alert, AlertTitle, useTheme } from '@mui/material';
import React from 'react';
import { ComponentValue, SchemaComponent } from '/src/types/schema';

interface NoticeProps {
  comp: SchemaComponent;
  value: ComponentValue;
}

const Notice = ({ comp }: NoticeProps) => {
  const theme = useTheme();

  return (
    <Alert severity="info" variant={theme.palette.mode === 'light' ? 'standard' : 'outlined'}>
      {comp.title && <AlertTitle>{comp.title}</AlertTitle>}
      {comp.value as string}
    </Alert>
  );
};

export default Notice;
