import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { SxProps, Theme, styled } from '@mui/material/styles';
import React, { PropsWithChildren, useState } from 'react';

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  px: theme.spacing(0),
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: `${theme.spacing(0)} ${theme.spacing(0.5)}`,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: `${theme.spacing(0)} ${theme.spacing(0.5)}`,
  marginBottom: theme.spacing(1),
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

interface CollapsibleProps {
  open?: boolean;
  summary: React.ReactNode;
  sx?: SxProps<Theme>;
  unmountOnExit?: boolean;
}

const Collapsible = ({
  open = true,
  unmountOnExit = true,
  ...props
}: PropsWithChildren<CollapsibleProps>) => {
  const [expandedLocations, setExpandedLocations] = useState(open);
  const showHideLocations = () => {
    setExpandedLocations(!expandedLocations);
  };
  const { summary, children, sx } = props;
  return (
    <Accordion
      square
      expanded={expandedLocations}
      onChange={showHideLocations}
      variant="elevation"
      disableGutters
      TransitionProps={{ unmountOnExit }}
      elevation={0}
      sx={{ ...sx, border: 'none', boxShadow: 'none' }}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={
          <ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '1rem', marginRight: 1 }} />
        }>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapsible;
