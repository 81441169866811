import {
  Stack,
  FormControl,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { locationGuids } from './api';

import { useLocationTabContext } from './AddEditLocation';

const BasicInfo = () => {
  const { baseData: location, setBaseData, setHasError, hasError } = useLocationTabContext();

  const validateForm = () => {
    setHasError(false);
    if (
      location?.title.length < 1 ||
      location?.externalId.length < 1 ||
      location?.locationType.length < 1
    ) {
      setHasError(true);
    }
  };

  if (!location) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mt: 2 }} spacing={2} direction="row" justifyContent="flex-start">
        <FormControl required sx={{ flex: '1 1 25%' }}>
          <TextField
            error={location?.externalId.length < 1 && hasError}
            size="small"
            value={location?.externalId}
            label="Id for external system"
            required
            onBlur={validateForm}
            onChange={(e) => {
              setBaseData({ ...location, externalId: e.target.value });
              validateForm();
            }}
          />
        </FormControl>
        <FormControl required sx={{ flex: '1 1 25%' }}>
          <TextField
            error={location?.title.length < 1 && hasError}
            size="small"
            value={location?.title}
            label="Name of location object"
            required
            onBlur={validateForm}
            onChange={(e) => {
              setBaseData({ ...location, title: e.target.value });
              validateForm();
            }}
          />
        </FormControl>
        <FormControl required sx={{ flex: '1 1 25%' }}>
          <TextField
            error={location.locationType.length < 1}
            label="Type of location"
            select
            required
            size="small"
            value={location?.locationType}
            onChange={(e) => {
              const descriptionRequired =
                e.target.value !== 'de81c69f-2826-4662-8bd4-a98fe9c88ea5' &&
                e.target.value !== 'f96b4c84-abd4-45d8-a264-67794bdd323b';
              setBaseData({ ...location, descriptionRequired, locationType: e.target.value });
            }}>
            {locationGuids.map((dt) => {
              return (
                <MenuItem key={dt.key} value={dt.key}>
                  {dt.value}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </Stack>
      <Stack sx={{ mt: 2 }} spacing={2} direction="row" justifyContent="flex-start">
        <FormGroup sx={{ flex: '0 1 auto' }}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(location.isHidden)}
                title="Hide this location from being displayed in SimplyByCoor client"
                onChange={(e) => {
                  setBaseData({ ...location, isHidden: e.target.checked });
                }}
              />
            }
            label="Hidden"
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(location.isSelectable)}
                title={'Is location selectable'}
                onChange={(e) => {
                  setBaseData({ ...location, isSelectable: e.target.checked });
                }}
              />
            }
            label="Selectable"
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(location.descriptionRequired)}
                title="Require description to be filled in when selecting this location"
                onChange={(e) => {
                  setBaseData({ ...location, descriptionRequired: e.target.checked });
                }}
              />
            }
            label="Require description"
          />
        </FormGroup>
        <FormControl sx={{ flex: '1 1 auto' }}>
          <TextField
            sx={{ flex: '0 1 auto', alignSelf: 'flex-end', width: '300px' }}
            size="small"
            value={location.externalSiteId || ''}
            label={'Override external site id'}
            onChange={(e) => {
              setBaseData({ ...location, externalSiteId: e.target.value });
            }}
            fullWidth
            margin="dense"
            variant="outlined"
          />
        </FormControl>
      </Stack>
    </>
  );
};

export default BasicInfo;
