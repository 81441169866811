import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Addchart } from '@mui/icons-material';

import { Branding } from './QRDesign';
import ImageInput from './ImageInput';
import { ActionButton } from '../../../ActionButton';
import { Site } from '/src/types/site';
import { useBrandingManager } from './hooks/useBrandingManager';

interface BackgroundImageDesignProps {
  brandings: Branding[] | undefined;
  apiUrl: string;
  currentSite: string | Site | null;
}

const BackgroundImageDesign = ({ brandings, apiUrl, currentSite }: BackgroundImageDesignProps) => {
  const defaultBrandingId = '7ea22bef-39a3-4835-bfc0-df1108c8265a';
  const {
    generatePreview,
    previewImage,
    createNewBranding,
    saveBranding,
    deleteBranding,
    brandingChanged,
    handleChange,
    closeDialog,
    handleDeleteButton,
    iconSizeChanged,
    fetchPdf,
    handleQrCodesToPrintChange,
    handleUpdatePreview,
    closePreview,
    previewUrl,
    showPreview,
    isReadOnly,
    localBranding,
    setLocalBranding,
    handleScaleFactorChange,
    selectedBranding,
    showConfirmDelete,
    usePrintingGuides,
    setUsePrintingGuides,
    printPng,
    setPrintPng,
    qrCodesToPrint,
  } = useBrandingManager({
    brandings,
    defaultBrandingId,
    apiUrl,
    currentSite,
  });

  const handleGenerateCodes = async () => {
    if (!selectedBranding) return;
    await fetchPdf(selectedBranding);
  };
  const onUpdatePreview = async () => {
    if (!localBranding) return;
    await generatePreview(localBranding);
  };
  return (
    <>
      <Stack direction="row" gap={2}>
        <Box sx={{ flex: '1' }}>
          <FormControl fullWidth>
            <FormLabel>Select a branding (or create new)</FormLabel>
            <ButtonGroup fullWidth>
              <Select
                sx={{ flex: '1 1 auto', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size="small"
                value={selectedBranding?.id || ''}
                onChange={brandingChanged}
                fullWidth>
                {brandings?.map((d) => {
                  return (
                    <MenuItem key={d.id as string} value={d.id as string}>
                      {d?.name?.length > 0 ? d.name : 'Unnamed branding'}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                sx={{ width: 'auto' }}
                onClick={createNewBranding}
                color="info"
                aria-label="Create new template"
                endIcon={<Addchart />}>
                New
              </Button>
            </ButtonGroup>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              disabled={isReadOnly}
              size="small"
              fullWidth
              label="Name"
              onChange={handleChange('name')}
              onBlur={(e) => {
                if (e.target.value.length < 1) {
                  setLocalBranding({ ...brandings, name: 'Unnamed branding' } as Branding);
                }
              }}
              value={localBranding?.name || ''}></TextField>
          </FormControl>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl sx={{ flex: '1 1 50%' }}>
              <FormLabel>Scale factor</FormLabel>
              <Slider
                min={0}
                step={0.1}
                max={1}
                valueLabelDisplay="auto"
                disabled={isReadOnly}
                aria-label="Scale factor"
                value={localBranding?.qrCodeScaleFactor || 1}
                onChange={handleScaleFactorChange}
                color="info"
              />
            </FormControl>

            <FormControl sx={{ flex: '1 1 50%' }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.backgroundImageUrl || ''}
                label={'Background image'}
                labelDisabled={true}
                onChange={handleChange('backgroundImageUrl')}
                sx={{ marginTop: '-10px', '& .MuiFormLabel-root': { fontSize: '12px' } }}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                label="Background color"
                onChange={handleChange('backgroundColor')}
                value={localBranding?.backgroundColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('foregroundColor')}
                label="Foreground color"
                value={localBranding?.foregroundColor || ''}></TextField>
            </FormControl>
          </Stack>

          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>Icon size</FormLabel>
                <Slider
                  min={4}
                  step={1}
                  max={24}
                  valueLabelDisplay="auto"
                  disabled={isReadOnly}
                  aria-label="Icon size"
                  value={localBranding?.iconSize || 4}
                  onChange={iconSizeChanged}
                  color="info"
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.iconUrl || ''}
                label={'Icon url'}
                onChange={handleChange('iconUrl')}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={usePrintingGuides}
                    onChange={(d) => setUsePrintingGuides(d.target.checked)}
                  />
                  Use printing guides
                </FormLabel>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={printPng}
                    disabled={qrCodesToPrint.split('\n').length > 1}
                    onChange={(d) => setPrintPng(d.target.checked)}
                  />
                  Print using png
                </FormLabel>
              </FormControl>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={onUpdatePreview}
              variant="contained"
              color="info"
              startIcon={<CachedIcon />}>
              Update preview
            </Button>
          </Box>
        </Box>
        <Box sx={{ flex: '1' }}>
          <Stack direction="column" gap={2}>
            <FormControl sx={{ flex: '1' }}>
              <FormLabel>Selected qr codes to print</FormLabel>
              <TextField
                multiline={true}
                size="small"
                fullWidth
                id="selected-qr-codes"
                maxRows={6}
                onChange={handleQrCodesToPrintChange}
                value={qrCodesToPrint}
              />
            </FormControl>
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}>
              <img
                src={previewImage}
                style={{
                  aspectRatio: '87/97', // Keep aspect ratio for non-square images
                  maxWidth: '50%', // Limit the width to 50%
                  objectFit: 'cover', // Ensures image fits container
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mt: 2 }}>
        <Stack direction="row" gap={2} sx={{ mt: 2 }}>
          <Stack direction="row" gap={2} flex="1">
            {!isReadOnly && (
              <Box sx={{ alignSelf: 'flex-start' }} display="flex">
                <Button
                  onClick={handleDeleteButton}
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverIcon />}>
                  Delete
                </Button>
              </Box>
            )}

            {!isReadOnly && (
              <Box justifyContent="flex-end" display="flex" flex="1">
                <Button
                  disabled={!localBranding?.backgroundImageUrl} // Disable if no background image is set
                  onClick={saveBranding}
                  variant="contained"
                  color="success">
                  Save branding
                </Button>
              </Box>
            )}
          </Stack>
          <Box flex="1" alignItems="flex-end" justifyContent="flex-end" display="flex">
            <ActionButton
              action={handleGenerateCodes}
              variant="contained"
              color="info"
              disabled={qrCodesToPrint.length < 1}>
              Generate codes
            </ActionButton>
          </Box>
        </Stack>
      </Box>
      <Dialog
        open={showConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete branding</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this branding, it can't be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => localBranding && deleteBranding()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        transitionDuration={{ appear: 100, enter: 100, exit: 300 }}
        open={showPreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription"
        onClick={closePreview}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <img src={previewUrl} alt="QR Code Preview" style={{ maxWidth: '80%', maxHeight: '80%' }} />
      </Backdrop>
    </>
  );
};

export default BackgroundImageDesign;
