import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Contact } from '/src/types/site';

interface ContactFormProps {
  values: Contact[];
  onValueChange: (values: Contact[]) => void;
}

const ContactForm = ({ values, onValueChange }: ContactFormProps) => {
  const [contactName, setContactName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [contactList, setContactList] = useState(values || []);

  const addContact = (e: React.MouseEvent) => {
    e.preventDefault();
    if (contactInfo.length > 0 && contactName.length > 0) {
      const tmpContactList = [...contactList];
      tmpContactList.push({ name: contactName, contactInformation: contactInfo });
      setContactList(tmpContactList);
      onValueChange(tmpContactList);
      setContactName('');
      setContactInfo('');
    }
  };

  const removeContact = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    const tmpContactList = [...contactList];
    tmpContactList.splice(index, 1);
    setContactList(tmpContactList);
    onValueChange(tmpContactList);
  };

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ mt: 0, mb: 1 }}>
          Contacts *
        </Typography>
        {contactList.length > 0 && (
          <List sx={{ width: '100%', pt: 0 }} dense>
            {contactList.map((contact: Contact, index: number) => {
              return (
                <ListItem
                  key={`${contact.name}-${contact.contactInformation}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeContact(e, index)}>
                      <Delete />
                    </IconButton>
                  }
                  divider>
                  <ListItemText primary={contact.name} secondary={contact.contactInformation} />
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            value={contactName}
            label="Contact name"
            onChange={(e) => setContactName(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            value={contactInfo}
            label="Contact information"
            onChange={(e) => setContactInfo(e.target.value)}
            multiline
            minRows="1"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={addContact}
              disabled={contactInfo.length < 1 || contactName.length < 1}>
              Add contact
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactForm;
